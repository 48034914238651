import "./IncomingSteps.css"
import { backendWriter } from "../../../App"

export const getUniqueAuthors = async (authorIds: string[]) => {
  const authorPromises = authorIds.map((authorId) => backendWriter.getAuthorInfo(authorId))
  const authorInfoObjects = await Promise.all(authorPromises)
  return authorInfoObjects
}

const IncomingSteps = () => {
  return <></>
}

export default IncomingSteps
