import { useContext } from "react"
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom" //useNavigate, useParams
import "./LandingPage.css"
// import typing from "../../assets/landingpage5.gif"
import plexusAnimationFolding from "../../assets/plexusAnimateClosing.gif"

//auth 1
import AuthContext from "../../ReactContexts/AuthContext"
import { logEvent } from "firebase/analytics"
import { analytics, auth, provider } from "../App"
import { signInWithPopup } from "firebase/auth"
import { ArrowRight, House } from "@phosphor-icons/react"
// import { logEvent } from "firebase/analytics"
// import { analytics, auth, provider } from "../App"
// import { signInWithPopup } from "firebase/auth"
// import { ArrowRight } from "@phosphor-icons/react"

const LandingPage = () => {
  const navigate = useNavigate()
  const url = useLocation()
  const { person } = useContext(AuthContext)
  const { placeId } = useParams()
  //If on the home page
  return person && url.pathname !== "/landing" ? (
    <Navigate to="/p" />
  ) : (
    <div className="landing-page">
      <div className="landing-page-text">
        <div className="top-part">
          <img
            src={plexusAnimationFolding}
            style={{ maxWidth: "80vw", width: "120pt", marginTop: "-20%" }}
          ></img>
          <div className="big-statement">
            {/* <a href="https://plexus.substack.com/p/inverting-the-internet">Plexus</a> */}
            Walk through Plexus.
            {/* connect through mutual thoughts,<br></br>not mutual friends. */}
          </div>

          <div className="small-statement">
            Plexus is a public benefit corp, building interfaces for lots of people to connect their
            thoughts together. Walk is a pro-interface, meant for helping you get lost in the graph.
          </div>
          <br></br>

          {/* <a
            className="small-statement"
            href="https://plexus.substack.com/p/inverting-the-internet"
          >
            there's so much more to you<br></br>than we could ever know.
          </a> */}

          {/* <br></br> */}
          {/* <div
            className="enter-plexus "
            onClick={() => {
              const code = "test"
              if (!person) {
                logEvent(analytics, code + "-invite-page-click", {
                  timestamp: Date.now(),
                  personEmail: person?.email,
                  personGoogleDisplayName: person?.displayName,
                  personId: person?.uid,
                  inviteCode: code,
                })

                //go to loading page
                navigate("/p/" + (placeId ?? "forum") + "/authenticating")
                //used to redirect
                signInWithPopup(auth, provider).then((val) => {
                  //signed in
                  const person = val?.user
                  logEvent(analytics, code + "invite-page-sign-in", {
                    timestamp: Date.now(),
                    personEmail: person?.email,
                    personGoogleDisplayName: person?.displayName,
                    personId: person?.uid,
                    person: person,
                    inviteCode: code,
                  })
                })
                //email verification link
              } else {
                navigate("/")
                logEvent(analytics, code + "-invite-page-click-but-already-logged-in", {
                  timestamp: Date.now(),
                  personEmail: person?.email,
                  personGoogleDisplayName: person?.displayName,
                  personId: person?.uid,
                  inviteCode: code,
                })
              }
            }}
          >
            walk with us &nbsp;
            <ArrowRight />
          </div> */}

          {/* <button
            className="primary-enter-button"
            onClick={() => {
              window.location.href = "https://voices.plexus.earth"
            }}
          >
            Join Voices (new)
          </button> */}

          <button
            className="primary-enter-button"
            onClick={() => {
              const code = "test"
              if (!person) {
                logEvent(analytics, code + "-invite-page-click", {
                  timestamp: Date.now(),
                  personEmail: person?.email,
                  personGoogleDisplayName: person?.displayName,
                  personId: person?.uid,
                  inviteCode: code,
                })

                //go to loading page
                navigate("/p/" + (placeId ?? "forum") + "/authenticating")
                //used to redirect
                signInWithPopup(auth, provider).then((val) => {
                  //signed in
                  const person = val?.user
                  logEvent(analytics, code + "invite-page-sign-in", {
                    timestamp: Date.now(),
                    personEmail: person?.email,
                    personGoogleDisplayName: person?.displayName,
                    personId: person?.uid,
                    person: person,
                    inviteCode: code,
                  })
                })
                //email verification link
              } else {
                navigate("/")
                logEvent(analytics, code + "-invite-page-click-but-already-logged-in", {
                  timestamp: Date.now(),
                  personEmail: person?.email,
                  personGoogleDisplayName: person?.displayName,
                  personId: person?.uid,
                  inviteCode: code,
                })
              }
            }}
          >
            Walk with us <ArrowRight />
          </button>
          <button
            className="secondary-enter-button"
            onClick={() => (window.location.href = "https://plexus.earth")}
          >
            Return Home <House />
          </button>
        </div>

        <br></br>
        {/* <img src={typing} style={{ width: "200pt", maxWidth: "40vw" }}></img>
        <br></br> */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <div className="link-row">
            <a href="https://plexus.substack.com/">blog</a>
            <a href="https://twitter.com/plexusearth">twttr</a>
            <a href="https://innovative-phosphorus-3ab.notion.site/Joining-Plexus-370dd63ea16d463e8b34ec629625d344">
              hiring
            </a>
            <br></br>
            <a href="https://docs.google.com/document/d/1BoqTmmcaNUlaur7tG4o7Pdm8rRCoHLNZ/edit?usp=sharing&ouid=112785312491031312241&rtpof=true&sd=true">
              privacy
            </a>
            &nbsp;
            <a href="https://docs.google.com/document/d/1B8n2TaviaGY7knglzkk1OSezraFzpS9p/edit?usp=sharing&ouid=112785312491031312241&rtpof=true&sd=true">
              terms
            </a>
          </div>
        </div>
      </div>
      <span></span>
    </div>
  )
}

export default LandingPage
