import { Editor, Node, NodeEntry } from "slate"
import { FormattedText } from "./EditorTSConfig"

export const getSlateEditorString = (editor: Editor) => {
  const iterator = Node.texts(editor)
  let nextString = iterator.next()
  let result = ""
  while (!nextString.done) {
    result += (nextString.value as NodeEntry<FormattedText>)[0].text
    nextString = iterator.next()
    result += nextString.done ? "" : "\n"
  }

  return result
}
