import React, { useContext, useEffect, useRef, useState } from "react"
import "./Write.css"
import { getOpenAiTextEmbedding } from "../Firebase/OpenAi"
import { backendGetThoughtSuggestionsByVector } from "../Firebase/FirebaseFunctionPointers"
import { Cite, ThoughtWithSuggestions } from "./WriteTypes"
import ThoughtComponent from "./ThoughtComponent"
import CitationsList from "./CitationsList"
import { focusOnDiv, isCursorAtEnd, isCursorAtStart } from "./utils/interactDOM"
import { createDocument, fetchDocument, updateDocument } from "./firebase/firebaseServices"
import { useDocumentContext } from "./contexts/DocumentContext"
import { useNavigate, useParams } from "react-router-dom"
import { debounce } from "./utils/debounce"
import FinishDraftButton from "./FinishDraftButton"
import AuthContext from "../ReactContexts/AuthContext"

const Write: React.FC = () => {
  const { person } = useContext(AuthContext)

  const [thoughts, setThoughts] = useState<ThoughtWithSuggestions[]>([{ text: "", citations: [] }])
  const { documentId: docIdFromUrl } = useParams<{ documentId?: string }>()
  const { documentId, setDocumentId } = useDocumentContext()
  const [activeThoughtIndex, setActiveThoughtIndex] = useState<number | null>(null)
  const thoughtEditableRefs = useRef<(HTMLDivElement | null)[]>([])
  const [title, setTitle] = useState("")
  const [cites, setCites] = useState<Cite[]>([])
  const navigate = useNavigate()

  const handleBlur = (index: number, event: React.FocusEvent) => {
    const target = event.target as HTMLDivElement
    let updatedText = target.textContent || ""
    let updatedThoughts = [...thoughts]
    updatedThoughts[index].text = updatedText
    setThoughts(updatedThoughts)
  }
  console.log(person?.uid, "pesronuid")
  const handleDocumentCreate = async () => {
    const id = await createDocument(
      title,
      thoughts,
      cites,
      person?.uid ?? "",
      person?.email ?? "",
      person?.displayName ?? ""
    )
    setDocumentId(id)
    return id
  }

  useEffect(() => {
    if (!docIdFromUrl && !documentId) {
      handleDocumentCreate().then((newId) => {
        navigate(`/write/${newId}`)
      })
    } else if (docIdFromUrl && !documentId) {
      setDocumentId(docIdFromUrl)
    }
  }, [docIdFromUrl, documentId, navigate, setDocumentId])

  useEffect(() => {
    if (documentId) {
      console.log("Fetch document triggered")
      // Fetch the document data when documentId is set.
      fetchDocument(documentId, (data) => {
        if (data) {
          setTitle(data.title || " ") // default to space if title is not set in Firebase
          setThoughts(data.thoughts || [])
          setCites(data.cites || [])
        }
      })
    }
  }, [documentId])

  const debouncedUpdate = debounce(() => {
    if (documentId) {
      updateDocument(documentId, title, thoughts, cites)
    }
  }, 3000)

  useEffect(() => {
    debouncedUpdate()
  }, [title, thoughts, cites, documentId, debouncedUpdate])

  const toggleCite = (
    thoughtIndex: number,
    suggestion: {
      id: string
      metadata: { text: string; authorName: string; timestamp: string; authorEmail: string }
    }
  ) => {
    const existingCite = cites.find((c) => c.id === suggestion.id)
    let updatedThoughts = [...thoughts]
    if (existingCite) {
      // If the cite exists, remove it
      setCites((prev) => prev.filter((c) => c.id !== suggestion.id))
      updatedThoughts[thoughtIndex].citations = updatedThoughts[thoughtIndex].citations.filter(
        (cite) => cite !== existingCite.id
      )
    } else {
      // If the cite doesn't exist, add it
      setCites((prev) => [
        ...prev,
        {
          id: suggestion.id,
          text: suggestion.metadata.text,
          timestamp: suggestion.metadata.timestamp,
          authorName: suggestion.metadata.authorName,
          authorEmail: suggestion.metadata.authorEmail,
        },
      ])
      if (updatedThoughts[thoughtIndex]) {
        if (!updatedThoughts[thoughtIndex].citations) {
          updatedThoughts[thoughtIndex].citations = []
        }
        updatedThoughts[thoughtIndex].citations.push(suggestion.id)
      }
    }
    setThoughts(updatedThoughts)
  }

  const fetchEmbeddingsAndSuggestions = async (text: string) => {
    const textEmbedding = await getOpenAiTextEmbedding(text)
    const vector = textEmbedding.data.data ? textEmbedding.data.data[0].embedding : undefined
    if (vector) {
      return await backendGetThoughtSuggestionsByVector(vector, 50, "forum")
    }
    return null
  }

  const handleThoughtChange = async (index: number, event: React.KeyboardEvent) => {
    // debouncedFetchEmbeddingsAndSuggestions(index, text)
    if (event.key === "Enter") {
      event.preventDefault()
      const target = event.target as HTMLDivElement
      const text = target.textContent || ""

      //first, anytime you press enter adds a new thought below where you are...
      //todo: add the text from after your cursor that line.
      const newThought: ThoughtWithSuggestions = { text: "", suggestions: undefined, citations: [] }
      setThoughts((prev) => [...prev, newThought])

      const suggestions = await fetchEmbeddingsAndSuggestions(text)

      //we want to do this on every keystroke, fine for now.
      setThoughts((prevThoughts) =>
        prevThoughts.map((thought, i) => {
          if (i !== index) return thought
          return { ...thought, text, suggestions }
        })
      )
    }
    // Check for arrow keys:
    if (
      (event.key === "ArrowUp" && isCursorAtStart(event.target)) ||
      (event.key === "ArrowDown" && isCursorAtEnd(event.target))
    ) {
      // Prevent default behavior (which would just move the cursor within the same div)
      event.preventDefault()
      // Determine the next index to focus on
      const nextIndex = event.key === "ArrowUp" ? index - 1 : index + 1
      if (thoughtEditableRefs.current[nextIndex]) {
        focusOnDiv(thoughtEditableRefs.current[nextIndex], event.key)
      }
    }
  }

  useEffect(() => {
    //we also want to focus on the last thought, too whenever a new thought gets added
    //want the timeout because the thoughts state might update before the HTML DOM
    setTimeout(() => {
      const idToFocus = "writer-thought-editable-" + (thoughts.length - 1)

      document.getElementById(idToFocus)?.focus()
    }, 100)
  }, [thoughts.length])

  const handleDotClick = (index: number) => {
    if (activeThoughtIndex === index) {
      setActiveThoughtIndex(null)
    } else {
      setActiveThoughtIndex(index)
    }
  }

  return (
    <div className="writing-page">
      <div className="word-processor-container">
        <div
          className="editable-title"
          contentEditable={true}
          suppressContentEditableWarning={true}
          onBlur={(e) => setTitle(e.currentTarget.textContent || "")}
        >
          {title ?? ""}
        </div>
        <div className="thoughts">
          {thoughts.length > 0 &&
            thoughts.map((thought, index) => (
              <ThoughtComponent
                key={index}
                thought={thought}
                index={index}
                handleThoughtChange={handleThoughtChange}
                handleBlur={handleBlur}
                handleDotClick={handleDotClick}
                activeThoughtIndex={activeThoughtIndex}
                cites={cites}
                toggleCite={toggleCite}
                editableRef={(el) => (thoughtEditableRefs.current[index] = el)}
              />
            ))}
        </div>
        {thoughts.length > 0 && thoughts[0].text.length > 4 ? (
          <FinishDraftButton title={title} documentId={documentId || ""} cites={cites} />
        ) : (
          <></>
        )}
        <CitationsList cites={cites} />
      </div>
    </div>
  )
}

export default Write
