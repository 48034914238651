import React from "react"
import { Cite } from "./WriteTypes"

type Props = {
  title: string
  documentId: string
  cites: Cite[]
}

const FinishDraftButton: React.FC<Props> = ({ title, documentId, cites }) => {
  const constructMailto = () => {
    const uniqueAuthorEmails = [...new Set(cites.map((cite) => cite.authorEmail))].join(",") // Assuming each cite has an authorEmail attribute
    const subject = encodeURIComponent(
      `I cited you in a piece I wrote${title.length > 1 ? ": '" + title + "'" : ""}`
    )
    const body = encodeURIComponent(
      `Here's the link${
        title.length > 1 ? " to " + title : ""
      }: www.plexus.earth/write/${documentId}`
    )

    return `mailto:${uniqueAuthorEmails}?subject=${subject}&body=${body}`
  }

  return (
    <div className="finish-draft-button-container">
      <button
        className="finish-draft-button"
        onClick={() => {
          const mailtoURL = constructMailto()
          window.location.href = mailtoURL
        }}
      >
        Share Draft
      </button>
    </div>
  )
}

export default FinishDraftButton
