import { Star, TrashSimple } from "@phosphor-icons/react"
import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { TextPost } from "../../ReactContexts/PostContext"
import { backendWriter } from "../App"
import { ThoughtWalkStepWithId } from "./WalkInterfaces"
import "./StrikeButton.css"
import "./WalkStep.css"
import { child, getDatabase, onValue, ref } from "firebase/database"
import AuthContext from "../../ReactContexts/AuthContext"
import { isMobile } from "../Editor/EditorContainer"
import moment from "moment"
import DisplayContext from "../../ReactContexts/DisplayContext"

export const WalkStep = ({
  step,
  thought,
  processThoughtClick,
  walkStepType: small,
  lastItem,
  isSelected,
  isFirstStep,
  shouldntAppear,
  isPastStep,
  isReply,
}: {
  step: ThoughtWalkStepWithId
  thought: TextPost
  processThoughtClick: (thought: TextPost, wasFirstStepInWalk: boolean) => void
  walkStepType: "small" | "stepthrough-preview" | "" | undefined | boolean
  lastItem?: "lastItem" | "notLast"
  isSelected?: boolean
  isFirstStep: boolean
  isLastStep: boolean
  shouldntAppear?: boolean
  isPastStep?: "past-step"
  isReply?: boolean
}) => {
  const { placeId } = useParams()
  const [hover, setHover] = useState(false)
  const { person } = useContext(AuthContext)
  const { otherDisplaySettings } = useContext(DisplayContext)

  // getNumTimesTraversed(thought);

  const [realStep, setRealStep] = useState<ThoughtWalkStepWithId>()
  useEffect(() => {
    const db = getDatabase()
    const domainRef = ref(db, "p/" + (placeId ?? "forum")) //nodes are posts
    const stepRef = child(domainRef, `personSteps/${person?.uid}/steps/${step?.walkStepId}`)

    onValue(stepRef, (data) => {
      if (data.exists()) setRealStep(data.val())
    })
  }, [])
  const isStrike = realStep?.strike?.timestamp > 0

  //if difference is within eight seconds, it's fresh. allowing buffer time. a bit janky
  const isFresh = step?.timestamp - thought?.timestamp < 8000

  if (!thought) return <></>

  return (
    //get number of times this thought was tread
    //number of unique authors to visit it
    <div
      className={
        "thought-walk-step " +
        (isFresh ? " fresh " : " stale ") +
        (isReply ? " reply " : "") +
        (otherDisplaySettings?.indentLikeConversation ? " conversation-mode " : "") +
        isPastStep +
        " " +
        small +
        " " +
        (lastItem ?? "") +
        (isSelected ? " selected" : "") +
        (shouldntAppear ? " smaller" : "") +
        (isMobile() ? " isMobile" : "")
      }
      onClick={(e) => {
        if (e.metaKey) backendWriter.addWalkBreak()
        if (thought.id) processThoughtClick(thought, isFirstStep)
      }}
      onMouseOut={() => {
        setHover(false)
      }}
      onMouseOver={() => {
        setHover(true)
      }}
    >
      <div className="row">
        <div style={{ color: "rgb(160,160,160)" }}>
          {thought?.authorName} {isReply ? "(in reply ^) " : ""}&nbsp;
          {moment(thought?.timestamp).fromNow()}&nbsp;
        </div>
        {/* &nbsp;&nbsp;&nbsp; */}
        <Star
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            backendWriter.toggleStrike(step)
          }}
          size={18}
          className="strike-button"
          weight={isStrike ? "fill" : "regular"}
          //isSelected && isn't small means it's one of the previous steps, which means it can be stricken
          style={{ visibility: isSelected || (!small && hover) ? "visible" : "hidden" }}
        />

        <TrashSimple
          size={18}
          className="strike-button"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            if (window.confirm("Would you like to delete this step?")) {
              backendWriter.deleteStep(backendWriter.personId, step.walkStepId)
              // .then(() => window.location.reload())
            }
          }}
          style={{ visibility: !small && (hover || isSelected) ? "visible" : "hidden" }}
        />
      </div>

      <div className="row">
        <div className="inner-step-row" style={{ fontWeight: isStrike ? "bold" : "normal" }}>
          {thought.text}
        </div>
      </div>
    </div>
  )
}
