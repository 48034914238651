import React from "react"
import { ThoughtWithSuggestions } from "./WriteTypes"
import "./ThoughtComponent.css"

interface ThoughtProps {
  thought: ThoughtWithSuggestions
  index: number
  handleThoughtChange: (index: number, event: React.KeyboardEvent) => void
  handleBlur: (index: number, event: React.FocusEvent) => void
  handleDotClick: (index: number) => void
  activeThoughtIndex: number | null
  cites: Array<{ id: string; text: string; timestamp: string; authorName: string }>
  toggleCite: (thoughtIndex: number, suggestion: any) => void
  editableRef: React.Ref<HTMLDivElement>
}

const ThoughtComponent: React.FC<ThoughtProps> = ({
  thought,
  index,
  handleThoughtChange,
  handleBlur,
  handleDotClick,
  activeThoughtIndex,
  cites,
  toggleCite,
  editableRef,
}) => {
  console.log("citations:", thought.citations)
  return (
    <div className="writer-thought">
      <div className="thought-content">
        <div
          id={"writer-thought-editable-" + index}
          className="thought-contenteditable"
          ref={editableRef}
          contentEditable={true}
          onKeyDown={(e) => handleThoughtChange(index, e)}
          onBlur={(e) => handleBlur(index, e)}
          suppressContentEditableWarning={true}
          data-placeholder="Write anything..."
        >
          {thought?.text}
        </div>
        {/* Citations */}
        {thought?.citations?.map((citationId) => {
          const citeIndex = cites.findIndex((c) => c.id === citationId)
          return (
            <span key={citationId} className="citation-number">
              [{citeIndex + 1}]{/* Tooltip for displaying citation text */}
              <div className="tooltip">
                {cites[citeIndex]?.text + " (" + cites[citeIndex]?.authorName + ")"}
              </div>
            </span>
          )
        })}
        {/* Dot container */}
        <div className="dot-container" onClick={() => handleDotClick(index)}>
          {thought?.suggestions?.suggestions?.matches.length ? (
            <div className="dot">
              {thought?.citations?.length > 0 ? thought.citations.length : ""}
            </div>
          ) : (
            <></>
          )}
          {activeThoughtIndex === index && thought.suggestions && (
            <div className="suggestions-popout">
              {thought?.suggestions.suggestions.matches.map((suggestion) => (
                <div
                  className={`suggestion ${
                    cites.find((c) => c.id === suggestion.id) ? "highlighted" : ""
                  }`}
                  key={suggestion?.id}
                  onClick={() => toggleCite(index, suggestion)}
                >
                  <div className="suggestion-text">{suggestion?.metadata?.text}</div>
                  <div className="suggestion-author">- {suggestion?.metadata?.authorName}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ThoughtComponent
