export const isCursorAtStart = (target: EventTarget | null) => {
  const selection = window.getSelection()
  if (!selection || !selection.focusNode || !target) return false

  const range = selection.getRangeAt(0)
  const boundingBox = range.getBoundingClientRect()
  const divBox = (target as HTMLElement).getBoundingClientRect()

  // Check if the cursor's Y position is close to the start of the div
  return selection.anchorOffset === 0 && Math.abs(boundingBox.top - divBox.top) < 5
}

export const isCursorAtEnd = (target: EventTarget | null) => {
  const selection = window.getSelection()
  if (!selection || !selection.focusNode || !target) return false

  const range = selection.getRangeAt(0)
  const boundingBox = range.getBoundingClientRect()
  const divBox = (target as HTMLElement).getBoundingClientRect()

  // Check if the cursor's Y position is close to the end of the div
  return (
    selection.anchorOffset === (target as HTMLElement).innerText.length &&
    Math.abs(boundingBox.bottom - divBox.bottom) < 5
  )
}

export const focusOnDiv = (
  div: HTMLDivElement | null | HTMLElement,
  arrowKey: "ArrowUp" | "ArrowDown" | "Enter"
) => {
  if (!div) return
  div.focus()

  const selection = window.getSelection()
  const range = document.createRange()

  //arrow key behavior
  if (arrowKey === "ArrowUp") {
    // Set cursor to the end
    range.selectNodeContents(div)
    range.collapse(false) // Collapse to end
  } else if ("ArrowDown") {
    // Set cursor to the start
    range.selectNodeContents(div)
    range.collapse(true) // Collapse to start
  }
  if (["ArrowUp", "ArrowDown"].includes(arrowKey)) {
    selection?.removeAllRanges()
    selection?.addRange(range)
  }
}
