import { useContext, useEffect, useMemo, useState } from "react"
import PostContext, {
  AppViewOption,
  ConnectionKind,
  EdgeAndThoughtPair2,
  PostMap,
  TextPost,
  WordMap,
} from "../ReactContexts/PostContext"
import { getDatabase, ref, onValue, set, query, orderByChild, equalTo } from "firebase/database"

import { PersonFirebaseBucket } from "../Firebase/FirebaseDatabaseInterfaces"
import { useParams } from "react-router-dom"
import AuthContext from "../ReactContexts/AuthContext"
import loader from "../assets/plexus-final2.gif"

import { getPlaceholder } from "./Editor/EditorContainer"
import { backendWriter } from "./App"
import { runOnboardingMessages, usernamex } from "./PrivateRoute/PrivateRoute"
import WalkAppContainer from "./WalkAppContainer/WalkAppContainer"
import FirebaseWriter from "../Firebase/FirebaseWriter"

import {
  ThoughtWalkStepMap,
  ThoughtWalkStepWithId,
  ThoughtWalkStepWithSrcThoughtToo,
  ThoughtWalkStepWithThought,
} from "./WalkAppContainer/WalkInterfaces"
import { EdgeInfoWithId, SingleConnectionUpdateForAPerson } from "../Types/types"
import { getEdgeAuthor } from "../Logic/ConnectionLogic"
import DisplayContext, { OtherDisplaySettings } from "../ReactContexts/DisplayContext"
import { stepThroughVisited } from "./WalkAppContainer/UnifiedWalk/IncomingSteps/OutgoingStepthroughPreview"
import { getFullEdgeMapArr } from "../Firebase/ReplyUtilities"

//when query changes, flip to global / personal if there are none in personal
//no need for this yet
export enum FilterMode {
  SENT,
  INBOX,
  ALL,
  CHAT,
  LIKES,
  SUGGESTIONS,
  UPDATES,
  SETTINGS,
}

export type SectionCountsType = {
  [section: string]: { unseen: number; unvisited: number; total: number }
}
const defaultStep: TextPost = {
  authorEmail: "davey@plexusnotes.com",
  authorId: "wSFLu3kTxYbJqvvqrRsnLpKDgIt1",
  authorName: "Davey",
  connections: {
    inbound: {
      "-NYtaxiG-1QWSX856riy": {
        "-N_4lRx3uHvXfoQ0RSRG": {
          id: "fake one",
          authorId: "mawesGojNjNizCPd8efh3wza8903",
          edgeAuthorId: "wSFLu3kTxYbJqvvqrRsnLpKDgIt1",
          edgeKind: ConnectionKind.TRAVERSAL,
          sourceId: "-NYtaxiG-1QWSX856riy",
          targetAuthorId: "wSFLu3kTxYbJqvvqrRsnLpKDgIt1",
          targetThoughtId: "-N_4lREm9kAHrNhQSmnO",
          timestamp: 1689092738797,
        },
      },
    },
  },
  id: "-N_4lREm9kAHrNhQSmnO",
  imageUrl:
    "https://oaidalleapiprodscus.blob.core.windows.net/private/org-5Aiti2G8v67N8LRG7DUUyX1M/user-ofh9qhAwiQd25LKc9VaP2SEN/img-3wFgX1fcZHZ51IlEWfc8rojn.png?st=2023-07-11T15%3A25%3A48Z&se=2023-07-11T17%3A25%3A48Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2023-07-10T23%3A42%3A25Z&ske=2023-07-11T23%3A42%3A25Z&sks=b&skv=2021-08-06&sig=lImXuwIKYYc%2Bx6FbB%2B2pvzXqM2E68o5k6TK85EVIBIw%3D",
  lastTraversed: {
    wSFLu3kTxYbJqvvqrRsnLpKDgIt1: 1689092738703,
  },
  slateValue: [
    {
      children: [
        {
          text: "What the heck is this place?",
        },
      ],
      type: "paragraph",
    },
  ],
  text: "What the heck is this place?",
  timestamp: 1689092735874,
  title: "Mysterious Locale Found",
}
// Other than routing logic (in App.tsx), this component contains the entire web app
// the primary function of this component: loading down data from firebase
function FirebaseDataContainer() {
  const [posts, setPosts] = useState<PostMap>({})

  const [dictionary, setDictionary] = useState<WordMap>({})
  const [loadingPosts, setLoadingPosts] = useState(true)
  const [filterMode, setFilterMode] = useState<FilterMode>(FilterMode.SENT)

  //domainID
  const { placeId, thoughtId, community } = useParams()
  const { person } = useContext(AuthContext)
  const [alerts, setAlerts] = useState()
  const [communityAuthors, setCommunityAuthors] = useState<string[]>([])

  //get the person's step arr here
  const [mySteps, setMySteps] = useState<ThoughtWalkStepMap>({})
  const [loadingSteps, setLoadingSteps] = useState(true)

  //get all the posts with a timestamp (apparently some posts used to not have a timestamp?)
  // I guess it's good to only get the ones with a timestamp

  //every time thoughtId changes, record it
  const [allBreadcrumbs, setAllBreadcrumbs] = useState([])
  useEffect(() => {
    setAllBreadcrumbs((x) => [...x, { id: thoughtId }])
    // window.alert(
    //   "breadcrumbs: " + stringOfBreadcrumbs(getPathToCurrentThought(ThoughtBreadcrumbs, thoughtId))
    // )
  }, [thoughtId])

  useEffect(() => {
    if (!person) return
    console.log(person?.uid)
    const domainId = placeId ?? "forum"
    const db = getDatabase()
    const alertsRef = ref(db, `p/${domainId}/people/${person?.uid}/surveys`)
    onValue(alertsRef, (data) => {
      // Get timestamp of last login
      if (data.exists) {
        setAlerts(data.val())
      }
    })

    //retrieve steps for this person, if exist
    const stepsRef = ref(db, `p/${domainId}/personSteps/${person?.uid}/steps`)
    onValue(stepsRef, (data) => {
      if (data.exists()) {
        const stepMap = data.val()
        setMySteps(stepMap)
        console.log("person has " + Object.keys(stepMap).length + " steps.")
      } else {
        console.warn("steps data does not exist yet")
      }
    })
  }, [person])

  const [personBucket, setPersonBucket] = useState<PersonFirebaseBucket>()

  //update person from firebase
  //person > get firebase info (do this in data container)
  useEffect(() => {
    const db = getDatabase()
    if (!person || !("uid" in person)) return

    const personRef = ref(db, "p/" + (placeId ?? "forum") + "/people/" + person?.uid) //nodes are posts

    //get person data on the front end
    onValue(personRef, (data) => {
      if (data.exists()) {
        setPersonBucket(data.val())
      } else {
        //otherwise, initiali
        const personBucket: PersonFirebaseBucket = {
          enteredDoor: null,
          personName: null,
          personEmail: person.email,
        }
        set(personRef, personBucket)

        //otherwise, write to this location with enough stuff
      }
    })

    //grab firebase display name etc.
  }, [person])

  useEffect(() => {
    if (!community) return
    const db = getDatabase()
    const authorsRef = ref(db, `p/${placeId}/communities/${community}`)
    onValue(authorsRef, (snapshot) => {
      const authorsMap = snapshot.val()
      const authorsArray = Object.keys(authorsMap || {}) // If authorsMap is null, use an empty object
      console.log(authorsArray)
      setCommunityAuthors(authorsArray)
    })
  }, [])

  //once person bucket data exist, initialize firebase

  useEffect(() => {
    if (!personBucket) return

    //otherwise, ensure name exists
    let theName: string
    if (personBucket.personName) {
      //either grab the person name from firebase, or ask for a new one
      theName = personBucket.personName
    }
    if (!theName) {
      if (usernamex.length > 1) {
        theName = usernamex
      } else {
        theName = runOnboardingMessages()
      }
    }

    //then, set up firebase writer
    const domainId = placeId ?? "forum"
    const db = getDatabase()
    const domainRef = ref(db, "p/" + domainId) //nodes are posts

    //finally, initialize backend writer
    if (person.uid && person.email) {
      backendWriter.initialize(
        domainRef,
        person.uid,
        person.email,
        theName,
        getPlaceholder(placeId)
      )
      // Saves email to firebase
      backendWriter.recordPersonEmail(person.email)
      // Updates lastLogin timestamp directly before we mark the writer as initialized
      // NOTE: This is different than the timestamp on person.metadata.lastSignInTime, which is the last time somebody used google auth to sign in
      // This tracks the last time somebody opens plexus
      if (!backendWriterInitialized) {
        backendWriter.recordPersonLastLogin(Date.now())
      }
      setWriterInitialized(true)
    }
  }, [personBucket])
  const [backendWriterInitialized, setWriterInitialized] = useState(false)

  const [myOwnPosts, setMyOwnPosts] = useState<TextPost[]>()
  //new logic for retrieving posts
  const [myNodes, setMyNodes] = useState<PostMap>({})
  useEffect(() => {
    const domainId = placeId ?? "forum"
    const db = getDatabase()
    // Create a reference to the "nodes" location in the database
    const nodesRef = ref(db, "p/" + domainId + "/nodes")
    // Query the database to get only the nodes with a specific authorId
    const nodesByAuthor = query(nodesRef, orderByChild("authorId"), equalTo(person.uid))

    // Listen for value changes on the query result
    onValue(nodesByAuthor, (snapshot) => {
      // Get an array of the node objects that match the query
      const nodesMap: PostMap = snapshot.val()

      // Add the nodes to the cache
      for (let nodeId in nodesMap) {
        FirebaseWriter.addToCache(nodeId, nodesMap[nodeId])
      }

      // Set the state with the array of nodes
      setMyNodes(nodesMap)
      setMyOwnPosts(Object.values(nodesMap).filter((e) => e.id && e.timestamp)) //make sure it looks like a node
    })
  }, [])

  useEffect(() => {
    if (backendWriter && person?.uid) backendWriter.setPersonId(person?.uid)
  }, [person])
  const [personalFilter, setPersonalFilter] = useState(true)
  const [relevanceFilter, setRelevanceFilter] = useState(false)

  const todaysPrompt = useMemo(() => {
    //set in firebase
    const prompt = getPlaceholder(placeId)
    return prompt
  }, [placeId])
  const [testRunComplete, setTestRunComplete] = useState<boolean>(false)
  useEffect(() => {
    if (!testRunComplete) {
      setTestRunComplete(true)
    }
  }, [])
  const [titleThoughtId, setTitleThoughtId] = useState<string>()

  const generateConversationId = (id1: string, id2: string) => {
    let a = [id1, id2].sort()
    return a[0] + a[1]
  }

  const routeToConversation = (thought: TextPost, currentUserId: string) => {
    // Initializes conversation, sends email/intro messages, saves data to db an triggers conversation count increment
    const newConversationId = generateConversationId(thought.authorId, currentUserId)
    // Automatically redirects to the chat that the resonated thought is linked to
    setFilterMode(FilterMode.CHAT)
    setConversationId(newConversationId)
  }
  const [conversationId, setConversationId] = useState<string>()

  // Tracks who has been to a given community
  useEffect(() => {
    // Ensure both user and community are available
    if (person.uid && community && backendWriterInitialized) {
      // Assuming you have an instance of your Firebase class (e.g. firebaseInstance)
      backendWriter.addAuthorToCommunity(person.uid, community)
    }
  }, [person, community, backendWriterInitialized])

  const [incomingSteps, setIncomingSteps] = useState([])
  const [outgoingSteps, setOutgoingSteps] = useState([])
  const [incomingReplies, setIncomingReplies] = useState([])

  const [sectionCounts, setSectionCounts] = useState<SectionCountsType>({})

  //where do outgoing edges get written
  //what is thirdbound?
  //Get incoming steps
  useEffect(() => {
    const fetchOutgoing = async () => {
      //then also fetch the incoming traversal thoughts
      const outboundRaw = Object.values(personBucket?.connections?.outbound ?? {})
        .filter(
          (personConnections: SingleConnectionUpdateForAPerson) =>
            personConnections?.edgeKind === ConnectionKind.TRAVERSAL &&
            getEdgeAuthor(personConnections) !== backendWriter.personId
        )
        .reverse()
      //make steps from each of these things/
      const actualSteps: ThoughtWalkStepWithId[] = outboundRaw.map(convertEdgeToStep)
      //ids of thoughts stepped to from here
      const ids = actualSteps.map((step) => step.targetThoughtId)
      //thoughts stepped to from my thoughts
      const thoughts: TextPost[] = await backendWriter.queryByIds(ids)
      //added these thoughts to the steps object
      const withThoughts: ThoughtWalkStepWithThought[] = actualSteps.map((step, i) => {
        return { ...step, thought: thoughts[i] }
      })
      //ids of my thoughts
      const sourceIds = actualSteps.map((step) => step.previousThoughtId)
      //content of my thoughts
      const sourceThoughts: TextPost[] = await backendWriter.queryByIds(sourceIds)
      //all combined together into same data structure:
      const withSourceThoughtsToo: ThoughtWalkStepWithSrcThoughtToo[] = withThoughts.map(
        (step: ThoughtWalkStepWithThought, i) => {
          return { ...step, sourceThought: sourceThoughts[i] }
        }
      )
      //
      const defaultFirstNotif: ThoughtWalkStepWithSrcThoughtToo = {
        walkStepId: "fake-outgoing-step-for-default-step", //totally arbitrary
        targetThoughtId: "-N_4lREm9kAHrNhQSmnO",
        thought: defaultStep as TextPost, //thought is the thing to be displayed
        sourceThought: undefined,
        previousThoughtId: undefined,
        traversalEdgeId: undefined,
        stepperId: "plexus-default",
        timestamp: 0,
        wasFirstStepInWalk: true,
        wasLastStepInWalk: false,
      }
      const final = [...withSourceThoughtsToo, defaultFirstNotif]

      const uniqueOutgoing: ThoughtWalkStepWithSrcThoughtToo[] = final.reduce(
        (arrSoFar, nextStep) => {
          if (arrSoFar.map((e) => e.targetThoughtId).includes(nextStep.targetThoughtId))
            return arrSoFar
          else return [...arrSoFar, nextStep]
        },
        []
      )

      setOutgoingSteps(uniqueOutgoing)

      //filter for just replies
      const justRepliesReal: ThoughtWalkStepWithSrcThoughtToo[] = uniqueOutgoing
        .map((step) => {
          //make sure there's a reply edge from target to source
          const targetThought = step?.thought
          const sourceThought = step?.sourceThought
          if (!targetThought || !sourceThought) return undefined
          if (targetThought.authorId === person.uid) return undefined
          //find if there's an edge
          const incomingEdges = getFullEdgeMapArr(
            sourceThought?.connections?.inbound ?? {},
            ConnectionKind.REPLY
          )
          const rightEdges = incomingEdges.filter((e) => e?.sourceId === targetThought?.id)
          const theEdge: EdgeAndThoughtPair2 =
            rightEdges.length > 0
              ? { edge: rightEdges[0], targetThought: targetThought, sourceThought: sourceThought }
              : undefined

          return theEdge
        })
        .filter((e) => e) //check that exists
        .map((e: EdgeAndThoughtPair2): ThoughtWalkStepWithSrcThoughtToo => {
          return {
            ...convertEdgeToStep(e.edge),
            thought: e.targetThought,
            sourceThought: e.sourceThought,
          }
        })

      setIncomingReplies(justRepliesReal)
      setSectionCounts((x) => {
        //calculate unseen
        const unvisited = calcUnvisitedNotifs(justRepliesReal)
        const lastPeakTimestamp = personBucket?.timestamps?.lastPeakedAllUpdates
        const unseen = calcUnseenNotifs(justRepliesReal, lastPeakTimestamp)
        const total = calcNotifs(justRepliesReal)
        return { ...x, notifications: { unvisited, unseen, total } }
      })
      //query by ids to get the right thoughts as well.
    }
    fetchOutgoing()
    //
  }, [personBucket?.connections?.outbound, personBucket?.timestamps?.lastPeakedAllUpdates])

  //display settings
  const [appView, setAppView] = useState<AppViewOption>("walk")
  const [enableStrikes, setEnableStrikes] = useState<boolean>(true)
  const [showWalkthroughs, setShowWalkthroughs] = useState<boolean>(false)
  const [showPastSteps, setShowPastSteps] = useState<boolean>(false)
  const [otherDisplaySettings, setOtherDisplaySettings] = useState<OtherDisplaySettings>({})

  return (
    <PostContext.Provider
      value={{
        posts,
        setPosts,
        dictionary,
        setDictionary,
        personalFilter,
        setPersonalFilter,
        relevanceFilter,
        setRelevanceFilter,
        loadingPosts,
        setLoadingPosts,
        todaysPrompt,
        titleThoughtId,
        setTitleThoughtId,
        filterMode,
        setFilterMode,
        conversationId,
        setConversationId,
        routeToConversation,
        personBucket,
        setPersonBucket,
        allBreadcrumbs,
        setAllBreadcrumbs,
        myOwnPosts,
        myNodes,
        setMyNodes,
        communityAuthors,
        mySteps,
        setMySteps,
        incomingSteps,
        setIncomingSteps,
        setLoadingSteps,
        loadingSteps,
        incomingReplies,
        setIncomingReplies,
        outgoingSteps,
        setOutgoingSteps,
        sectionCounts,
        setSectionCounts,
      }}
    >
      <DisplayContext.Provider
        value={{
          appView,
          setAppView,
          enableStrikes,
          setEnableStrikes,
          showWalkthroughs,
          setShowWalkthroughs,
          showPastSteps,
          setShowPastSteps,
          otherDisplaySettings,
          setOtherDisplaySettings,
        }}
      >
        {/* person bucket needs to be defined before this renders */}
        {/* in particular for backendWriter to be defined before walk renders */}
        {backendWriterInitialized ? <WalkAppContainer alerts={alerts} /> : <>loading...</>}
        {loadingSteps ? (
          <div
            style={{
              position: "absolute",
              zIndex: 10,
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundColor: "white",
            }}
          >
            <img src={loader} style={{ width: "10vw", minWidth: "100pt", margin: "0 auto" }} />
          </div>
        ) : (
          <></>
        )}
      </DisplayContext.Provider>
    </PostContext.Provider>
  )
}

export default FirebaseDataContainer

const calcUnvisitedNotifs = (arr: ThoughtWalkStepWithSrcThoughtToo[]): number => {
  const unseenOnes = arr?.filter((step) => !stepThroughVisited(backendWriter.personId, step))
  const num = unseenOnes?.length
  return num ?? 0
}
const calcNotifs = (arr: ThoughtWalkStepWithSrcThoughtToo[]): number => {
  return arr?.length ?? 0
}

const calcUnseenNotifs = (
  arr: ThoughtWalkStepWithSrcThoughtToo[],
  lastCheckedNotifs: number
): number => {
  // const unvisitedOnes = arr?.filter((step) => !stepThroughVisited(backendWriter?.personId, step))
  const unseen = arr.filter((e) => e.timestamp > lastCheckedNotifs)
  return unseen?.length ?? 0
}

const convertEdgeToStep = (personConnection: EdgeInfoWithId | SingleConnectionUpdateForAPerson) => {
  const { targetThoughtId, sourceId, id, timestamp } = personConnection
  const theStep: ThoughtWalkStepWithId = {
    targetThoughtId, // id of not necessarily my thought
    previousThoughtId: sourceId, //my thought I think (source step id?)
    traversalEdgeId: id, //associated traversal edge
    timestamp, //timestamp when stepped
    stepperId: getEdgeAuthor(personConnection),
    //these are irrelevant, jsut required by the type
    wasFirstStepInWalk: true,
    wasLastStepInWalk: false,
    walkStepId: "fake-id-" + targetThoughtId + "--" + sourceId + id,
  }
  return theStep
}
