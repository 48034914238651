// const sgMail = require("@sendgrid/mail")
import emailjs from "@emailjs/browser"
import { logEvent } from "firebase/analytics"
import { analytics } from "./Components/App"
import { getAllReplies } from "./Components/Feed/checkIfMutual"
import { thoughtIsReply } from "./Firebase/ReplyUtilities"
import { TextPost } from "./ReactContexts/PostContext"

// Triggers an emailjs function to send a beautiful invite. Pretty blank email, most of the formatting is done ahead of time.
// https://dashboard.emailjs.com/admin/templates/pij4u1w
export const sendBeautifulInvite = (toEmail: string, body: string) => {
  emailjs.send(
    "service_d0v34fj",
    "template_u7blj2i",
    {
      toEmail: toEmail,
      body: body,
    },
    "VFXAIIMaqEenjBrC9"
  )
}

export const sendTraversalEmails = (toEmail: string, step_count: number, to_name: string) => {
  console.log("hit")
  emailjs.send(
    "service_d0v34fj",
    "template_mrvwg2d",
    {
      toEmail: toEmail,
      step_count: step_count,
      to_name: to_name,
    },
    "VFXAIIMaqEenjBrC9"
  )
}

export const sendWalkPartnerEmail = (
  toEmail: string,
  n_steps: number,
  to_name: string,
  walk_partner_name: string,
  walk_partner_email: string,
  steps: string
) => {
  emailjs.send(
    "service_d0v34fj",
    "template_neq1lbg",
    {
      toEmail: toEmail,
      n_steps: n_steps,
      to_name: to_name,
      walk_partner: walk_partner_name,
      walk_partner_email: walk_partner_email,
      steps: steps,
    },
    "VFXAIIMaqEenjBrC9"
  )
}

/**
 *
 */
export const sendEmailIfFirstReply = (parentThought: TextPost, replyThought: TextPost) => {
  // Don't allow get notifications from yourself
  if (parentThought.authorId === replyThought.authorId) return

  //check that it's the first reply and that the parent thought is a root thought
  //send more emails, don't check for this condition anymore.
  if (getAllReplies(parentThought).length > 0 || thoughtIsReply(parentThought)) return

  //if it is the first reply, send email
  return sendFirstReplyEmail(
    parentThought.authorName,
    replyThought.authorName,
    parentThought.text,
    replyThought.text,
    parentThought.authorEmail,
    replyThought.authorEmail,
    parentThought.id,
    replyThought.id
  )
}

// 'template_wcklet9
// '

export function firstWalkNotiForPlexusTeam(from_name: string, walkId: string, walk_number: string) {
  if (from_name)
    emailjs.send(
      "default_service",
      // Need to change template!
      "template_wcklet9",
      {
        from_name,
        walkId,
        walk_number,
      },
      "VFXAIIMaqEenjBrC9"
    )

  logEvent(analytics, "first walk", {
    timestamp: Date.now(),
    from_name,
    walkId,
  })
}
export function sendFirstReplyEmail(
  toName: string = "anonymous",
  fromName: string = "anonymous",
  toThought: string = "text hidden",
  fromThought: string = "text hidden",
  toEmail: string,
  fromEmail: string = "forum",
  toIDthought: string,
  fromIDthought: string
) {
  if (toEmail && fromEmail)
    emailjs
      .send(
        "default_service",
        // Need to change template!
        "template_icmpkas",
        {
          toName,
          fromName,
          toThought,
          fromThought,
          toEmail,
          fromEmail,
          toIDthought,
          fromIDthought,
        },
        "VFXAIIMaqEenjBrC9"
      )
      .then(() => {
        // congratulateOnMatch()
      })

  logEvent(analytics, "send-reply-email", {
    timestamp: Date.now(),
    nameA: toName,
    nameB: fromName,
    thoughtA: toThought,
    thoughtB: fromThought,
    emailA: toEmail,
    emailB: fromEmail,
    IDthoughtA: toIDthought,
    IDthoughtB: fromIDthought,
  })
}

export function sendUsFeedbackEmail(
  fromName: string = "anonymous",
  fromThought: string = "text hidden",
  fromEmail: string = "forum",
  feedbackTopic: string
) {
  if (fromThought)
    emailjs
      .send(
        "default_service",
        // Need to change template!
        "template_a2rrm7v",
        {
          fromName,
          fromThought,
          fromEmail,
          feedbackTopic,
        },
        "VFXAIIMaqEenjBrC9"
      )
      .then(() => {
        // congratulateOnMatch()
      })

  logEvent(analytics, "send-reply-email", {
    timestamp: Date.now(),
    nameB: fromName,
    thoughtB: fromThought,
    emailB: fromEmail,
  })
}
