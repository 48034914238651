import { getDatabase, onValue, ref } from "firebase/database"
import moment from "moment"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import {
  PeopleFirebaseBucket,
  PersonStepsFirebaseBucket,
  SurveyWithPersonInfo,
} from "../../../../Firebase/FirebaseDatabaseInterfaces"
import { PostMap, TextPost } from "../../../../ReactContexts/PostContext"
import { ThoughtWalkStep, ThoughtWalkStepWithId } from "../../../WalkAppContainer/WalkInterfaces"
import { WalkStep } from "../../../WalkAppContainer/WalkStep"
import { retrieveAuthorEmail } from "../../functions/sortData"
import "./StepStats.css"
import { filterMapByMinimum, getSteppersFromSteps, getStepsInTimespan } from "./StepStatsLogic"

export default function StepStats() {
  const { placeId } = useParams()

  const [thoughtsMap, setThoughtsMap] = useState<PostMap>({})
  const thoughtsArr: TextPost[] = Object.values(thoughtsMap)
  const [_surveys, setSurveys] = useState<SurveyWithPersonInfo[]>([])
  const [_surveysOpen, setSurveysOpen] = useState<SurveyWithPersonInfo[]>([])

  const [_loading, setLoading] = useState(true)
  const [_loadingThoughtsMap, setLoadingThoughtsMap] = useState(true)

  //the main steps object
  const [personSteps, setPersonSteps] = useState<PersonStepsFirebaseBucket>({})
  useEffect(() => {
    const db = getDatabase()
    const pathToWalks = ref(db, `p/${placeId ?? "forum"}/personSteps`)
    onValue(pathToWalks, (data) => {
      if (data.exists()) {
        const stepsData = data.val()
        console.log("WALKS", stepsData)
        setPersonSteps(stepsData)
        setLoading(false)
      }
    })

    //key for author ids
    const domainId = placeId ?? "forum"
    const postsRef = ref(db, "p/" + domainId + "/nodes") //nodes are posts
    onValue(postsRef, (snapshot) => {
      if (snapshot.exists()) {
        const value = snapshot.val()
        setThoughtsMap(value)
        setLoadingThoughtsMap(false)
        console.log(thoughtsMap)
      }
    })
    const surveysRef = ref(db, "p/" + domainId + "/people") //nodes are posts
    onValue(surveysRef, (snapshot) => {
      if (snapshot.exists()) {
        const value: PeopleFirebaseBucket = snapshot.val()
        //flatten them
        const surveysRaw = Object.entries(value ?? {})
          .filter(([_key, value]) => value?.surveys?.walkDisappointment)
          .map(([personId, personBucket]) => ({
            ...personBucket?.surveys?.walkDisappointment,
            personId,
            personName: personBucket?.personName,
            personEmail: personBucket?.personEmail,
          }))
          .filter((E) => E)

        const surveysRawOpen = Object.entries(value ?? {})
          .filter(([_key, value]) => value?.surveys?.walkDisappointmentOpen)
          .map(([personId, personBucket]) => ({
            ...personBucket?.surveys?.walkDisappointmentOpen,
            personId,
            personName: personBucket?.personName,
            personEmail: personBucket?.personEmail,
          }))
          .filter((E) => E)

        const definedSurveys = surveysRaw.filter((e) => ["1", "2", "3"].includes(e?.response))
        setSurveys(definedSurveys)
        setSurveysOpen(surveysRawOpen)
      }
    })
  }, [placeId])

  const lastSaturday = moment().startOf("isoWeek").subtract(2, "d").valueOf()
  const upcomingSaturday = moment().startOf("isoWeek").add(5, "d").valueOf()

  const [minInputTime, setMinInputTime] = useState<number>(lastSaturday)
  const [maxInputTime, setMaxInputTime] = useState<number>(upcomingSaturday)

  //more data
  //writers = thought-contributors
  const [stepMinForSteppersList, setStepMin] = useState(10)
  const [stepMin2ForSteppersList, setStepMin2] = useState(1)

  //organization of new admin panel
  interface SectionExpansionData {
    expanded?: boolean
    contributorsExpanded?: boolean
    contributors2Expanded?: boolean
  }
  const [expandedSections, setExpandedSections] = useState<{
    thoughts: SectionExpansionData
    steps: SectionExpansionData
    walks: SectionExpansionData
    disappointers: SectionExpansionData
  }>({
    thoughts: {},
    steps: {},
    walks: {},
    disappointers: {},
  })

  const [stepsInSpan, setStepsInSpan] = useState<ThoughtWalkStepWithId[]>()
  useEffect(() => {
    setStepsInSpan(getStepsInTimespan({ start: minInputTime, end: maxInputTime }, personSteps))
  }, [personSteps, minInputTime, maxInputTime])
  const numStepsInSpan = (stepsInSpan ?? []).length

  const [steppersInSpan, setSteppersInSpan] = useState<{ [stepperId: string]: ThoughtWalkStep[] }>()

  useEffect(() => {
    setSteppersInSpan(getSteppersFromSteps(stepsInSpan))
  }, [stepsInSpan])

  //list of steppers with a first minimum

  const stepperSorter = (a: any[], b: any[]) => {
    return b[1].length - a[1].length
  }
  const filteredSteppers = filterMapByMinimum(steppersInSpan ?? {}, stepMinForSteppersList).sort(
    stepperSorter
  )

  //want two lists of steppers
  const filteredSteppers2 = filterMapByMinimum(steppersInSpan ?? {}, stepMin2ForSteppersList).sort(
    stepperSorter
  )

  return (
    <div>
      <div>WALK STATISTICS</div>
      <br></br>
      <div>
        <span>timespan start: </span>
        <input
          type="date"
          id="startDateInput"
          onChange={(event) => setMinInputTime(moment(event.target.value).valueOf())}
          value={moment(minInputTime).format("YYYY-MM-DD")}
        />
        <br></br>
        <span>timespan end: </span>
        <input
          type="date"
          id="endDateInput"
          onChange={(event) => setMaxInputTime(moment(event.target.value).valueOf())}
          value={moment(maxInputTime).format("YYYY-MM-DD")}
        ></input>
        <br></br>
        <div>
          <div className="admin-stats-section-container">
            <strong>new steps</strong>
            <div
              className="admin-stats-section-inner"
              onClick={() =>
                setExpandedSections((x) => {
                  const previous = x
                  const newStepsObj: SectionExpansionData = {
                    ...previous.steps,
                    expanded: !previous.steps.expanded,
                  }
                  const newObj = { ...previous, steps: newStepsObj }
                  return newObj
                })
              }
            >
              {" "}
              [{expandedSections?.steps?.expanded ? "-" : "+"}]&emsp;steps: {numStepsInSpan ?? "?"}
            </div>
            {expandedSections?.steps?.expanded ? (
              <div style={{ margin: "1em" }}>
                {stepsInSpan.map((step) => {
                  const thought = thoughtsMap[step.targetThoughtId]
                  console.log(thoughtsArr)
                  return (
                    <>
                      {retrieveAuthorEmail(thoughtsArr, step?.stepperId) ?? "unknown stepper"}&emsp;
                      {moment(step.timestamp).fromNow()}
                      <WalkStep
                        {...{
                          step,
                          thought,
                          processThoughtClick: () => {},
                          walkStepType: true,
                          isFirstStep: false,
                          isLastStep: false,
                        }}
                      />
                    </>
                  )
                })}
              </div>
            ) : (
              ""
            )}
            <div
              className="admin-stats-section-inner"
              onClick={() =>
                setExpandedSections((x) => {
                  const previous = x
                  const newStepsObj: SectionExpansionData = {
                    ...previous.steps,
                    contributorsExpanded: !previous.steps.contributorsExpanded,
                  }
                  const newObj = { ...previous, steps: newStepsObj }
                  return newObj
                })
              }
            >
              {" "}
              [{expandedSections?.steps?.contributorsExpanded ? "-" : "+"}]&emsp;
              <u
                onClick={(e) => {
                  e.stopPropagation()

                  const newVal = Number(window.prompt("step min", String(stepMinForSteppersList)))
                  if (newVal) setStepMin(newVal)
                }}
              >
                {stepMinForSteppersList}
              </u>
              -steppers: &nbsp;
              {filteredSteppers.length} (recorded since 6/13)
            </div>
            {expandedSections?.steps?.contributorsExpanded ? (
              <div style={{ margin: "1em" }}>
                {filteredSteppers.map(([stepperId, steps]) => {
                  return (
                    <div>
                      {retrieveAuthorEmail(thoughtsArr, stepperId)} ({steps.length} steps)
                    </div>
                  )
                })}
              </div>
            ) : (
              ""
            )}
            <div
              className="admin-stats-section-inner"
              onClick={() =>
                setExpandedSections((x) => {
                  const previous = x
                  const newStepsObj: SectionExpansionData = {
                    ...previous.steps,
                    contributors2Expanded: !previous.steps.contributors2Expanded,
                  }
                  const newObj = { ...previous, steps: newStepsObj }
                  return newObj
                })
              }
            >
              {" "}
              [{expandedSections?.steps?.contributors2Expanded ? "-" : "+"}]&emsp;
              <u
                onClick={(e) => {
                  e.stopPropagation()

                  const newVal = Number(window.prompt("step min", String(stepMin2ForSteppersList)))
                  if (newVal) setStepMin2(newVal)
                }}
              >
                {stepMin2ForSteppersList}
              </u>
              -steppers: &nbsp;
              {filteredSteppers2.length} (recorded since 6/13)
            </div>
            {expandedSections?.steps?.contributors2Expanded ? (
              <div style={{ margin: "1em" }}>
                {filteredSteppers2.map(([stepperId, _steps]) => {
                  return (
                    <div>
                      {retrieveAuthorEmail(thoughtsArr, stepperId)}
                      {/* ({steps.length} steps) */}
                    </div>
                  )
                })}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
