import { useContext, useEffect, useMemo, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { createEditor, Descendant, Editor, Transforms } from "slate"
import { withHistory } from "slate-history"
import { withReact } from "slate-react"
import PostContext, { TextPost } from "../../../ReactContexts/PostContext"
import { backendWriter } from "../../App"
import { SimpleReplyInput } from "../../Detail/ReplyInputField/ReplyInputField"
import { initialValue } from "../../Editor/EditorContainer"
import { getSlateEditorString } from "../../Editor/SlateEditorString"
import getSuggestionsForOneThought, { SuggestedThoughtInfo } from "../../Feed/GetSuggestedThoughts"
import { getUniqueThoughts } from "../../../Logic/ConnectionLogic"
import PastSteps from "../PastSteps/PastSteps"
import { countSourceIdOccurrences, getThoughtsTraversedFromHere } from "../WalkAppContainer"
import {
  ThoughtWalkStep,
  ThoughtWalkStepWithId,
  ThoughtWalkStepWithThought,
} from "../WalkInterfaces"
import "./UnifiedWalk.css"
import CollapseWalkButton from "../OldUnifiedWalk/CollapseWalkButton/CollapseWalkButton"
import SingleNextStep from "../SingleNextStep/SingleNextStep"

export let focusedStepId: string
const UnifiedWalk: React.FC<{
  editorEmpty: boolean
  setEditorEmpty: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ editorEmpty, setEditorEmpty }) => {
  const { communityAuthors, mySteps, setLoadingSteps } = useContext(PostContext)
  const { placeId } = useParams()
  const authorsToFilterBy = communityAuthors
  const [embeddingsLoading, setEmbeddingsLoading] = useState<boolean>(false)

  //TODO replace logic for getting past step thoughts, based on past steps
  //when focused walk gets set, also set the orderedSteps, and the associated thoughts
  const [orderedPastStepThoughts, setOrderedPastStepThoughts] = useState<TextPost[]>([])
  const [orderedPastSteps, setOrderedPastSteps] = useState<ThoughtWalkStepWithThought[]>([])

  //get thougths from current walk
  //get steps from current walk
  const recentWalkSteps = useMemo(() => {
    let recents = []
    //get the index of the first step in this new walk, if exists
    const recentWalkFirstStepIndex = lastIndexWhere(
      orderedPastSteps,
      (step) => step.wasLastStepInWalk
    )
    if (recentWalkFirstStepIndex === -1) return []
    else {
      recents = orderedPastSteps.slice(recentWalkFirstStepIndex + 1)
    }

    return recents
  }, [orderedPastSteps])

  useEffect(() => {
    const fetchData = async () => {
      const rawSteps = mySteps ?? {}
      const rawStepsArr = Object.values(rawSteps)
      const sorter = (a: ThoughtWalkStep, b: ThoughtWalkStep) =>
        (a?.timestamp ?? 0) - (b?.timestamp ?? 0)
      const sortedRawSteps: ThoughtWalkStepWithId[] = rawStepsArr.sort(sorter)
      const stepThoughtIds: string[] = sortedRawSteps
        .map((step) => step.targetThoughtId)
        .filter((e) => e)

      try {
        const stepThoughts: TextPost[] = await backendWriter.queryByIds(stepThoughtIds)

        const stepsWithThoughts: ThoughtWalkStepWithThought[] = sortedRawSteps.map(
          (step: ThoughtWalkStepWithId) => ({
            ...step,
            thought:
              stepThoughts.filter((thought) => thought.id === step.targetThoughtId)[0] ?? undefined,
          })
        )

        const sortedFiltered = stepsWithThoughts.filter(
          (stepWithThought) => stepWithThought.timestamp && stepWithThought.thought?.id
        )

        setOrderedPastSteps(sortedFiltered)
        const justThoughts = sortedFiltered.map((e) => e.thought)
        setOrderedPastStepThoughts(justThoughts)
        setLoadingSteps(false)
      } catch (error) {
        console.error("An error occurred:", error)
      }
    }

    fetchData()
  }, [mySteps])

  //probably doesn't need to rerun EVERY time posts changes, but good for now.

  ///
  /**
   *
   * logic from other file:
   */

  //set focusedthought

  const lastStepThought: TextPost = useMemo(() => {
    if (orderedPastStepThoughts.length > 0)
      return orderedPastStepThoughts[orderedPastStepThoughts.length - 1]
  }, [orderedPastStepThoughts])

  //should be a parallel array (orderedPastSteps) with orderedPastStepThoughts
  const lastStep: ThoughtWalkStepWithId = useMemo(() => {
    if (orderedPastSteps.length > 0) return orderedPastSteps[orderedPastSteps.length - 1]
  }, [orderedPastSteps])
  const walkJustEnded = lastStep?.wasLastStepInWalk

  const focusedStep = walkJustEnded ? undefined : lastStep

  focusedStepId = focusedStep?.walkStepId
  const focusedStepThought = walkJustEnded ? undefined : lastStepThought

  //Get suggested thoughts every time myOwnPosts last post changes
  const [suggestions, setSuggestions] = useState<SuggestedThoughtInfo[]>([])

  const [associatedThoughts, setAssociatedThoughts] = useState<TextPost[]>([])

  //traversedThoughts
  const [traversedFromHereThoughts, setTraversedFromHereThoughts] = useState<TextPost[]>([])

  //Editor variables for reply input field (for field that's used in particular walks)
  const [value, _setValue] = useState(initialValue)
  const [editor, _setEditor] = useState(() => withHistory(withReact(createEditor())))

  //this is the useefffect where suggestions, and thoughts traversed from here, are gotten
  useEffect(() => {
    setAssociatedThoughts([])
    if (
      !focusedStepThought ||
      !focusedStepThought.id ||
      !focusedStepThought.text ||
      embeddingsLoading
    )
      return //if focused post is defined, get suggested posts
    //janky way to make sure embedding's been set
    getSuggestionsForOneThought(
      [focusedStepThought],
      placeId,
      true,
      true,
      true,
      authorsToFilterBy
    ).then((val) => {
      setSuggestions(val)
    })

    //first, get all the thoughts traversed from this focused thought
    //TODO rewrite to not use posts
    const traversedFromHerePromise = getThoughtsTraversedFromHere(lastStepThought)
    traversedFromHerePromise.then((traversedFromHereThoughtsNew) => {
      const sorted = traversedFromHereThoughtsNew.sort((a, b) => {
        const occurences = [a, b].map((e) => countSourceIdOccurrences(e, lastStepThought)?.length)
        return occurences[1] - occurences[0]
      })
      return setTraversedFromHereThoughts(sorted)
    })
  }, [focusedStepThought?.id, embeddingsLoading])

  //putting traversed thoughts first
  useEffect(() => {
    if (!lastStepThought) return

    const suggestedThoughts = suggestions.map((association) => association?.suggestedThought)
    const concated = [...(traversedFromHereThoughts ?? []), ...(suggestedThoughts ?? [])]
    const unique = getUniqueThoughts(concated).filter((e) => e)

    //take out the last two thoughts in my ordered touch posts
    const pastStepIdsFromThisWalk = recentWalkSteps.map((e: ThoughtWalkStep) => e?.targetThoughtId)
    const withoutPastSteps = unique.filter((e) => !pastStepIdsFromThisWalk.includes(e.id))

    if (withoutPastSteps.length > 0) setAssociatedThoughts(withoutPastSteps)
  }, [suggestions, traversedFromHereThoughts, lastStepThought])

  //   Functions for FB logic
  //Function to add a thought on the frontend
  const addThoughtFrontend = (children?: Descendant[]) => {
    //can just tell whether it's a reply based on whether the last thing was a lastStep or nothing
    const isntReply =
      !orderedPastSteps ||
      orderedPastSteps.length === 0 ||
      orderedPastSteps[orderedPastSteps.length - 1].wasLastStepInWalk
    const reply = !isntReply
    //Editor cleanup
    //get the text of the new thought to add
    const textOfNewPost = getSlateEditorString(editor).trim()
    if (!textOfNewPost) return
    // Reset the editor,
    Transforms.delete(editor, {
      at: {
        anchor: Editor.start(editor, []),
        focus: Editor.end(editor, []),
      },
    })

    let promise, embeddingsPromise, newThought

    if (reply) {
      const x = backendWriter.addReplyThought(
        textOfNewPost,
        lastStepThought,
        placeId,
        children ?? value
      )
      promise = x?.addPostPromise
      embeddingsPromise = x?.embeddingsPromise
      newThought = x?.newReplyThought
    } else {
      const x = backendWriter.addPost(children ?? value, textOfNewPost, placeId, undefined, [])
      promise = x?.addPostPromise
      embeddingsPromise = x?.embeddingsPromise
      newThought = x?.postWithId
    }
    return { promise, embeddingsPromise, newThought }
  }

  //for keyboard shortcuts
  const [selectedNextStepIndex, setSelectedNextStepIndex] = useState(0)
  //selected past step index, where 1 corresponds to the very last step taken (1 to # of ordered past steps always)
  const [selectedPastStepIndex, setSelectedPastStepIndex] = useState(1)

  //whether reply field is empty
  const [metaKeyDown, setMetaKeyDown] = useState(false)

  //deal with keydowns, particularly for selectedIndex

  //reset index every time focused thought changes
  useEffect(() => {
    setSelectedNextStepIndex(0)
  }, [lastStepThought?.id])
  useEffect(() => {
    const callback = (e: KeyboardEvent) => {
      if (e.key === "ArrowRight") {
        setSelectedNextStepIndex((i) => Math.min(i + 1, 9999))
      }
      if (e.key === "ArrowLeft") {
        setSelectedNextStepIndex((i) => Math.max(i - 1, 0))
      }
      if (e.key === "ArrowUp") {
        //prevent fidgety scrolll
        e.preventDefault()

        setSelectedPastStepIndex((i) => {
          return Math.min(i + 1, orderedPastSteps?.length ?? 1)
        })
      }
      if (e.key === "ArrowDown") {
        //prevent fidgety scrolll
        e.preventDefault()
        setSelectedPastStepIndex((i) => {
          return Math.max(i - 1, 1)
        })
      }

      //if enter and nothing in reply box
      if (e.key === "Enter" && editorEmpty) {
        if (!metaKeyDown) {
          const selectedThoughtInRow = walkJustEnded
            ? undefined // used to rely on incoming steps
            : associatedThoughts[selectedNextStepIndex]
          if (selectedThoughtInRow) {
            stepToExistingThought(selectedThoughtInRow, lastStep?.wasLastStepInWalk)
          }
        } else {
          //insert a break on meta + enter
          backendWriter.makeIntoLastStep(focusedStep.walkStepId)
        }
        //don't do anything on enter + cmd anymore, for now.
      }
      if (e.metaKey) setMetaKeyDown(true)
      else setMetaKeyDown(false)

      if (e.key === " " && editorEmpty) {
        e.preventDefault()
        const theStep = orderedPastSteps[orderedPastSteps.length - selectedPastStepIndex]
        if (theStep) {
          backendWriter.toggleStrike(theStep)
        }
      }
    }
    document.onkeydown = callback
    document.onkeyup = (e) => {
      if (e.metaKey) setMetaKeyDown(true)
      else setMetaKeyDown(false)
    }
  }, [associatedThoughts, editorEmpty, selectedNextStepIndex, selectedPastStepIndex, metaKeyDown])

  useEffect(() => {
    if (!editorEmpty) setSelectedNextStepIndex(undefined)
    else setSelectedNextStepIndex(0)
  }, [editorEmpty])

  const bottomRef = useRef(null)

  useEffect(() => {
    window.setTimeout(function () {
      var elems = document.getElementsByClassName("selected small")
      if (elems && elems.length > 0)
        elems[0].scrollIntoView({ inline: "center", block: "center", behavior: "auto" })
    }, 10)
  }, [selectedNextStepIndex])
  useEffect(() => {
    window.setTimeout(function () {
      var elems = document.getElementsByClassName("selected past-step")
      if (elems && elems.length > 0)
        elems[0].scrollIntoView({ inline: "center", block: "center", behavior: "auto" })
    }, 10)
  }, [selectedPastStepIndex])

  // Scrolls to bottom of page when new element is added, also does it on init
  useEffect(() => {
    if (bottomRef.current) {
      setTimeout(() => bottomRef.current.scrollIntoView({ behavior: "smooth" }), 50)
    }
  }, [orderedPastStepThoughts.length])

  // NEW LOGIC FOR ADDING A REPLY (MODIFICATIONS OF OLD LOGIC ABOVE)

  //TODO: calculate the first step in the current walk, then feed it in here
  let firstStepInCurrentWalk = Object.values(mySteps).reduceRight((firstStepInWalk, prevStep) => {
    if (firstStepInWalk) return firstStepInWalk
    else if (prevStep.wasFirstStepInWalk) return prevStep
  }, undefined)
  const stepToExistingThought = (thought: TextPost, isFirstStepAfterBreak: boolean) => {
    return backendWriter.stepToExistingThought(
      thought,
      lastStepThought,
      isFirstStepAfterBreak,
      firstStepInCurrentWalk?.walkStepId
    )
  }
  /**
   * adds a newly typed thought to the walk
   * 1. first adds the thought to the graph, with edges and all
   * 2. then takes a step to it
   * previously called handleSubmit
   */
  const addNewStepToWalk = async (_: any, children?: Descendant[]) => {
    const textOfNewPost = getSlateEditorString(editor).trim()

    //if there's no text, just don't run this
    if (!textOfNewPost || textOfNewPost === " ") return

    if (orderedPastStepThoughts.length === 0 && textOfNewPost.split(" ").length < 9) {
      window.alert(
        "The best walks start with a first step longer than eight words. And we want you to have the best walks :)"
      )
      return
    }
    setEmbeddingsLoading(true)
    const addThoughtFrontendResult = addThoughtFrontend(children)
    const { newThought, promise, embeddingsPromise } = addThoughtFrontendResult
    //2. Then step to it.
    try {
      await promise
      stepToExistingThought(newThought, lastStep?.wasLastStepInWalk)
      // wait for embeddings to resolve
      await embeddingsPromise
      setEmbeddingsLoading(false)
    } catch (error) {
      console.error(error) // Handle errors
    }
  }

  const [expandedStepIds, setExpandedStepIds] = useState<{ [stepId: string]: true }>({})
  const [seeBeforeCurrentWalk, setSeeBeforeCurrentWalk] = useState<boolean>(true)

  return (
    <div id="unified-walk-container">
      <div className="walk-app-top-half">
        <PastSteps
          {...{
            orderedPastStepThoughts: orderedPastStepThoughts ?? [],
            orderedPastSteps: orderedPastSteps ?? [],
            stepToExistingThought: stepToExistingThought,
            selectedIndex: selectedNextStepIndex,
            expandedStepIds,
            setExpandedStepIds,
            seeBeforeCurrentWalk,
            setSeeBeforeCurrentWalk,
            editorEmpty,
            selectedPastStepIndex: selectedPastStepIndex,
          }}
        />
        <SimpleReplyInput
          {...{
            editor,
            value,
            onSubmit: addNewStepToWalk,
            setEmpty: setEditorEmpty,
          }}
        />
        {true ? (
          !walkJustEnded ? (
            <SingleNextStep
              {...{
                focusedThought: lastStepThought,
                focusOnNewThought: stepToExistingThought,
                associatedThoughts: associatedThoughts,
                selectedIndex: selectedNextStepIndex,
                editorEmpty: editorEmpty,
                setSelectedIndex: setSelectedNextStepIndex,
              }}
            />
          ) : (
            <></>
          )
        ) : (
          <></>
        )}
        {/* only show collapse button if there's a last step, otherwise nothing to collapse */}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            visibility: lastStep?.wasLastStepInWalk ? "hidden" : "visible",
          }}
        >
          {orderedPastSteps.length > 0 ? (
            <CollapseWalkButton
              lastStepId={lastStep?.walkStepId}
              editor={editor}
              {...{ seeBeforeCurrentWalk, setExpandedStepIds }}
            />
          ) : (
            <></>
          )}
        </div>

        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
        <div ref={bottomRef}></div>
      </div>
    </div>
  )
}

export default UnifiedWalk

//get most recent steps up until the first walkend

function lastIndexWhere<T>(arr: T[], condition: (item: T) => boolean): number {
  for (let i = arr.length - 1; i >= 0; i--) {
    if (condition(arr[i])) return i
  }
  return -1
}
