import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./Components/App"

//analytics via logrocket
import LogRocket from "logrocket"
LogRocket.init("wp5nlu/plexus")

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(<App />)

//maybe helps with mobile?
const rootElement = document.querySelector(".root")
const viewPortH = rootElement.getBoundingClientRect().height
const windowH = window.innerHeight
const browserUiBarsH = viewPortH - windowH
//@ts-ignore
if ("style" in rootElement) rootElement.style.height = `calc(100vh - ${browserUiBarsH}px)`
