import { OpenAIApi, Configuration } from "openai"

function parseForOpenAi(text: string | string[]) {
  let textArr = (typeof text === "string" ? [text] : text).map((e) => e.replaceAll("\n", " "))
  textArr = textArr
    .map((t) => t.slice(0, 8000).replace(/[^a-z1-9 .,!?]|\s\s+/gi, ""))
    // NOTE: openAI throws a fit if text is too short
    .filter((t) => t.length >= 2)
  return textArr
}

export function getOpenAiTextEmbedding(text: string | string[], personId?: string): Promise<any> {
  //openai
  const configuration = new Configuration({
    apiKey: "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",
  })
  const openai = new OpenAIApi(configuration)
  const response = openai.createEmbedding({
    model: "text-embedding-ada-002",
    input: parseForOpenAi(text),
    user: personId,
  })
  return response
}
