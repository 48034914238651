import { ThoughtWalkStepWithThought } from "../WalkInterfaces"
import "./HideStepsToggle.css"

const HideStepsToggle = ({
  setShowPastSteps,
  showPastSteps,
  stepsBeforeThisWalk,
}: {
  setShowPastSteps: React.Dispatch<React.SetStateAction<boolean>>
  showPastSteps: boolean
  stepsBeforeThisWalk: ThoughtWalkStepWithThought[]
}) => {
  return (
    <div
      className="centered-gray-text toggle-past-steps"
      onClick={() => {
        setShowPastSteps((prev) => !prev)
        window.setTimeout(function () {
          var elem = document.getElementById("unified-walk-container")
          if (elem) elem.scrollTop = elem?.scrollHeight
        }, 300)
      }}
    >
      {showPastSteps ? "hide" : "see"} {stepsBeforeThisWalk.length} previous steps <br></br>
    </div>
  )
}

export default HideStepsToggle
