import { PersonStepsFirebaseBucket } from "../../../../Firebase/FirebaseDatabaseInterfaces"
import {
  ThoughtWalk,
  ThoughtWalkStep,
  ThoughtWalkStepWithId,
  WalkMap,
} from "../../../WalkAppContainer/WalkInterfaces"
import { getWalkStartTimestamp } from "../../../WalkAppContainer/WalkLogic/ThoughtWalkHelpers"

export interface DashboardTimespan {
  start: number
  end: number
}

//get all steps this week
export const getStepsInTimespan = (
  timespan: DashboardTimespan,
  allSteps: PersonStepsFirebaseBucket
): ThoughtWalkStepWithId[] => {
  //get flat array of steps
  const allStepsEver = Object.values(allSteps ?? {}).reduce((stepsSoFar, nextWalk) => {
    const newSteps = Object.values(nextWalk?.steps ?? {})
    return [...stepsSoFar, ...newSteps]
  }, [])
  const filteredSteps = allStepsEver
    .filter((step: ThoughtWalkStepWithId) => {
      return step.timestamp && step.timestamp < timespan.end && step.timestamp > timespan.start
    })
    .sort((a, b) => {
      return b.timestamp - a.timestamp
    })
  return filteredSteps
}

//get all walks this week
export const getWalksInTimespan = (
  timespan: DashboardTimespan,
  allWalks: WalkMap
): ThoughtWalk[] => {
  const walksArr = Object.values(allWalks ?? {})
  const filtered = walksArr
    .filter((walk) => {
      const walkStartTime = getWalkStartTimestamp(walk)
      return walkStartTime > timespan.start && walkStartTime < timespan.end
    })
    .reverse()
  return filtered
}

//get all steps this week
export const getSteppersFromSteps = (
  stepsInSpan: ThoughtWalkStep[]
  // stepMinimum: number
): { [stepperId: string]: ThoughtWalkStep[] } => {
  const newMap = stepsInSpan.reduce<{ [stepperId: string]: ThoughtWalkStep[] }>(
    (mapSoFar, nextStep) => {
      let newCopy = { ...mapSoFar }
      if (!nextStep.stepperId) return newCopy
      newCopy[nextStep.stepperId] = [...(mapSoFar[nextStep.stepperId] ?? []), nextStep]
      return newCopy
    },
    {}
  )
  return newMap
}
export const getWalkersInTimespan = (
  walksInSpan: ThoughtWalk[]
  // walkMinimum: number
): { [walkerId: string]: ThoughtWalk[] } => {
  const newMap = walksInSpan.reduce<{ [walkerId: string]: ThoughtWalk[] }>((mapSoFar, nextWalk) => {
    let newCopy = { ...mapSoFar }
    if (!nextWalk.authorId) return newCopy
    newCopy[nextWalk.authorId] = [...(mapSoFar[nextWalk.authorId] ?? []), nextWalk]
    return newCopy
  }, {})
  return newMap
}

export const filterMapByMinimum = (map: { [id: string]: any[] }, min: number) => {
  const filtered = Object.entries(map).filter(([_key, arrayValue]) => arrayValue.length >= min)
  return filtered
}
