import { signInWithPopup, User } from "firebase/auth"
import { useNavigate, useParams } from "react-router-dom"
import { auth, backendWriter, provider } from "../App"
import "./PrivateRoute.css"
import FirebaseDataContainer from "../FirebaseDataContainer"

const usernameLocationLocal = "plexus-anon-name" // "place-username"
export let usernamex = localStorage.getItem(usernameLocationLocal) ?? ""

export const runOnboardingMessages = () => {
  while (!usernamex) {
    usernamex = window.prompt("What should we call you? (We recommend your favorite emoji.)") ?? ""
  }
  localStorage.setItem(usernameLocationLocal, usernamex)
  backendWriter.setName(usernamex)
  return usernamex
}

/**
 * A page that redirects to landing page if not logged in, but enters plexus app if person is logged in
 * @param param0
 * @returns
 */
const PrivateRoute = ({ person }: { person?: User }) => {
  const { placeId } = useParams()
  const navigate = useNavigate()

  //when is oriented is defined, run firebase function then delete it

  return person ? (
    <FirebaseDataContainer />
  ) : (
    <div className="login-page">
      {/* <span style={{ display: "inline-flex", lineHeight: "2em" }}>{name}</span> */}
      <span
        style={{
          fontSize: ".7em",
          textAlign: "center",
          fontWeight: "normal",
          maxWidth: "500px",
          margin: "1em",
          lineHeight: "1.5em",
        }}
      >
        {/* {text} */}
      </span>
      <div
        className="login-button"
        onClick={() => {
          // Save current URL
          sessionStorage.setItem("preAuthURL", window.location.href)

          navigate((placeId ?? "forum") + "/authenticating")
          signInWithPopup(auth, provider)
        }}
      >
        enter plexus
      </div>
      <a
        style={{
          color: "gray",
          fontSize: ".6em",
          fontWeight: "normal",
          fontStyle: "italic",
          textDecoration: "none",
        }}
        href=" https://docs.google.com/document/d/1JvcOJpZJTIXtUfVmN3PVEtYG4POcKjNjVePKJC8WpW8/edit?usp=sharing"
      >
        privacy
      </a>
      <br></br>
      <br></br>
    </div>
  )
}

export default PrivateRoute
