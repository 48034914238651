import { useContext, useState } from "react"
import "./WalkAppContainer.css"
import "../Feed/FeedItem/Thought.css"
import PostContext, { ConnectionKind, TextPost } from "../../ReactContexts/PostContext"
import { getFullEdgeMapArr } from "../../Firebase/ReplyUtilities"
import WalkAppHeader from "./WalkAppHeader/WalkAppHeader"
import { backendWriter } from "../App"
import { Survey } from "../Survey/Survey"
import UnifiedWalk from "./UnifiedWalk/UnifiedWalk"
import moment from "moment"
import Settings from "../Settings/Settings"
import DisplayContext from "../../ReactContexts/DisplayContext"
import BellPage from "./BellPage/BellPage"
import SurveyPopup from "./SurveyPopup/SurveyPopup"
import PastWalksSection from "./UnifiedWalk/PastWalksSection"
import Write from "../../Write/Write"
import { useParams } from "react-router-dom"
import { Alerts } from "../../Types/types"

//shell interfaces, not really important anymore...

//The main component for walk
//contains everything you see on the screen in walk app
const WalkAppContainer: React.FC<{ alerts: Alerts }> = ({ alerts }) => {
  const { mySteps } = useContext(PostContext)
  const { appView, setAppView } = useContext(DisplayContext)
  const { providedAppView } = useParams()

  //if provided app view is social-writer, then switch it
  if (providedAppView === "social-writer" || providedAppView === "write")
    setAppView("social-writer")

  // Example usage for Davey. Pass in the array of ConnectionKind strings you want as the optional 5th variable
  // Thanks Micah will check this out soon. TODO
  // console.log(getAllUpdates(posts, personBucket, person.uid, false, ["REPLY", "TRAVERSAL"]))

  const [editorEmpty, setEditorEmpty] = useState(true)
  const checkAndRunSurvey = async (surveyName: string) => {
    // Could be replaced with logic that is in the "alerts" object to skip backendWriter stuff
    const survey = await backendWriter.getSurveyByName(surveyName)
    let oneWeekAgo
    let surveyTime
    if (survey) {
      oneWeekAgo = moment().subtract(1, "weeks")
      surveyTime = moment(survey.timestamp)
    }
    // Checks at least 10 steps have been taken and that the survey was last answered a week ago
    if (Object.keys(mySteps).length >= 10 && (!survey || surveyTime.isBefore(oneWeekAgo))) {
      // Run the survey
      Survey(alerts, true)
    }
  }
  checkAndRunSurvey("walkDisappointment")

  return (
    <div id="line-container">
      <SurveyPopup />

      <WalkAppHeader />
      {appView === "settings" ? (
        <Settings />
      ) : appView === "walk" ? (
        <UnifiedWalk
          {...{
            editorEmpty,
            setEditorEmpty,
          }}
        />
      ) : appView === "notifications" ? (
        <BellPage />
      ) : false && appView === "past-walks" ? (
        <PastWalksSection
          {...{
            editorEmpty,
            setEditorEmpty,
          }}
        />
      ) : appView === "social-writer" ? (
        <Write />
      ) : (
        <></>
      )}
    </div>
  )
}

export default WalkAppContainer

//TODO rewrite this function as async to use queryByIds
export const getThoughtsTraversedFromHere = async (
  focusedThought: TextPost
): Promise<TextPost[]> => {
  const rawEdges = focusedThought.connections?.outbound ?? {}
  const traversalOutboundEdges = getFullEdgeMapArr(rawEdges, ConnectionKind.TRAVERSAL)
  //get all the unique ones from different authors
  const targetThoughtIds = traversalOutboundEdges.map((edge) => edge.targetThoughtId)
  //this is the line where we should use queryByIds instead of getThoughtsByIds fn
  const targetThoughts = await backendWriter.queryByIds(targetThoughtIds)
  return targetThoughts
}

//gets all the author ids on incoming travversal edges
export const countSourceIdOccurrences = (
  targetThought: TextPost,
  sourceThought: TextPost
): string[] => {
  let uniqueAuthorIds: Set<string> = new Set<string>()

  if (!(targetThought && sourceThought)) return []

  // Loop through the inbound connections of thought1
  for (let key in targetThought.connections?.inbound ?? {}) {
    let connection = targetThought.connections?.inbound[key] ?? {}
    // Loop through the inner connections of each inbound connection
    for (let innerKey in connection) {
      if (
        connection[innerKey].sourceId === sourceThought.id &&
        connection[innerKey].edgeKind === "TRAVERSAL"
      ) {
        uniqueAuthorIds.add(connection[innerKey].edgeAuthorId)
      }
    }
  }

  // Loop through the outbound connections of thought1
  for (let key in targetThought.connections?.outbound ?? {}) {
    let connection = targetThought.connections?.outbound[key] ?? {}
    // Loop through the inner connections of each outbound connection
    for (let innerKey in connection) {
      if (
        connection[innerKey].targetThoughtId === sourceThought.id &&
        connection[innerKey].edgeKind === "TRAVERSAL"
      ) {
        uniqueAuthorIds.add(connection[innerKey].edgeAuthorId)
      }
    }
  }
  return Array.from(uniqueAuthorIds)
}

export const concatenateNames = (names: string[]) => {
  if (names.length === 1) {
    return names[0]
  } else if (names.length === 2) {
    return names[0] + " & " + names[1]
  } else if (names.length > 2) {
    let last = names.pop()
    return names.join(", ") + ", & " + last
  }
  return ""
}
