import "./IsolatedWalk.css"

const IsolatedWalk = () => {
  //first step id
  //need it to be recorded

  return <div className="isolated-walk-container">Isolated Walk</div>
}

export default IsolatedWalk
