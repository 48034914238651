//all these functions take a walk as input

import { thoughtIsReply } from "../../../Firebase/ReplyUtilities"
import { TextPost } from "../../../ReactContexts/PostContext"
import { abbreviate } from "../../../util"
import { ThoughtWalk, ThoughtWalkStep, ThoughtWalkStepWithId } from "../WalkInterfaces"

//NEED TO FILL IN EACH OF THESE

export const getWalkStartTimestamp = (walk: ThoughtWalk): number | undefined => {
  const timestamp = Object.values(walk.steps ?? {}).reduce(
    (earliestStepTime: number, nextStep: ThoughtWalkStep) => {
      if (!earliestStepTime) return nextStep.timestamp
      else if (nextStep.timestamp < earliestStepTime) return nextStep.timestamp
      else return earliestStepTime
    },
    undefined
  )
  return timestamp
}

const getLastStepInWalkTimestamp = (walk: ThoughtWalk): number | undefined => {
  const timestamp = Object.values(walk.steps ?? {}).reduce((latestStepTime, nextStep) => {
    if (nextStep.timestamp > latestStepTime) return nextStep.timestamp
    else return latestStepTime
  }, 0)
  return timestamp
}

export const getRepresentativeStep = (walk: ThoughtWalk): ThoughtWalkStep => {
  const steps = walk.steps ?? {}
  //get earliest
  const earliestStep = Object.values(steps).reduce(
    (earliestSoFar: ThoughtWalkStep, nextStep: ThoughtWalkStep) => {
      if (!earliestSoFar) return nextStep
      else if (nextStep.timestamp < earliestSoFar.timestamp) return nextStep
      else return earliestSoFar
    },
    undefined
  )
  return earliestStep
}

export const getWalkTitle = (walk: ThoughtWalk, firstThought?: TextPost) => {
  //get from first thought
  return (
    walk.walkTitle ?? firstThought?.title ?? abbreviate(firstThought?.text, 50) ?? "Untitled Walk"
  )
}

/**
 *
 * @param myWalks all the walks I've taken before which will show as previews
 * @param myPosts all my posts, which I'll filter to get the return result
 * @returns
 */
export const getRootThoughtsToShowAsWalkPreviews = (
  myWalks: ThoughtWalk[],
  myPosts: TextPost[]
): TextPost[] => {
  if (!myPosts) return []

  //get all thought ids that start walks
  const thoughtsThatStartWalks: string[] = (myWalks ?? [])
    .map((walk) => {
      const stepArr: ThoughtWalkStepWithId[] = Object.values(walk?.steps ?? {})
      const firstId = stepArr.length > 0 ? stepArr[0].targetThoughtId : undefined
      return firstId
    })
    .filter((e) => e) //filter out undefined

  //then get all the root thoughts that don't overlap
  const filteredPosts = myPosts.filter((post) => {
    return !thoughtIsReply(post) && !thoughtsThatStartWalks.includes(post.id)
  })
  return filteredPosts
}

/**
 * Gets timestamp for a walk or for a thought
 * @param walkOrThought
 * @returns
 */
export const getTimestampForThoughtOrWalk = (walkOrThought: ThoughtWalk | TextPost) => {
  //if it's a walk, get the time of the last step
  let timestamp: number = 0
  if ("steps" in walkOrThought) {
    timestamp = getLastStepInWalkTimestamp(walkOrThought)
  }
  //if it's a thought, get the creation timestamp
  //( make sure it has an id still)
  else if ("timestamp" in walkOrThought) {
    timestamp = walkOrThought.timestamp
  }
  return timestamp
}
