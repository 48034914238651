import { useState, useEffect, useContext } from "react"
import { concatenateNames, countSourceIdOccurrences } from "../WalkAppContainer"
import { WalkStep } from "../WalkStep"
import { backendWriter } from "../../App"
import PostContext, { TextPost } from "../../../ReactContexts/PostContext"
import "./SingleNextStep.css"
import { CaretLeft, CaretRight } from "@phosphor-icons/react"

const getUniqueAuthors = async (authorIds: string[]) => {
  const authorPromises = authorIds.map((authorId) => backendWriter.getAuthorInfo(authorId))
  const authorInfoObjects = await Promise.all(authorPromises)
  return authorInfoObjects
}

interface SingleNextStepProps {
  associatedThoughts: TextPost[]
  focusedThought: TextPost
  focusOnNewThought: (thought: TextPost, wasFirstStepInWalk: boolean) => void
  selectedIndex: number
  editorEmpty: boolean
  setSelectedIndex: React.Dispatch<React.SetStateAction<number>>
}

const SingleNextStep: React.FC<SingleNextStepProps> = ({
  associatedThoughts,
  focusedThought,
  focusOnNewThought,
  selectedIndex,
  editorEmpty,
  setSelectedIndex,
}) => {
  const { communityAuthors } = useContext(PostContext)
  let associatedThoughtsFiltered = associatedThoughts
  // If in a community space, filters associatedThoughts to include authors of that community
  if (communityAuthors && communityAuthors.length > 0) {
    associatedThoughtsFiltered = associatedThoughts.filter((associatedThought) => {
      return communityAuthors.includes(associatedThought.authorId)
    })
  }
  const [authorsByThoughtId, setAuthorsByThoughtId] = useState<Record<string, string[]>>({})

  // Used to get authorNames, the uniqueAuthors object also has their ids and emails on it, though
  useEffect(() => {
    const fetchAuthors = async () => {
      const authorsByThoughtIdTmp: Record<string, string[]> = {}

      for (const thought of associatedThoughtsFiltered) {
        const authorIds = countSourceIdOccurrences(thought, focusedThought)
        // console.log(authorIds)
        const uniqueAuthors = await getUniqueAuthors(authorIds)
        // console.log(uniqueAuthors)
        authorsByThoughtIdTmp[thought.id] = uniqueAuthors
          .map((authorInfoObj) => Object.values(authorInfoObj)[0]?.personName)
          .filter((name): name is string => !!name)
      }

      setAuthorsByThoughtId(authorsByThoughtIdTmp)
    }

    fetchAuthors()
  }, [associatedThoughts, focusedThought])

  /* associations row  */
  return (
    <div
      id="single-next-step-container"
      className="single-next-step-row"
      style={{ opacity: editorEmpty && associatedThoughtsFiltered.length > 0 ? "1" : "0" }}
      key={focusedThought?.id + "-associations"}
    >
      <CaretLeft
        size={32}
        color="gray"
        className={"caret " + (selectedIndex <= 0 ? "disabled" : "")}
        onClick={() => {
          setSelectedIndex((x) => {
            return Math.max(0, x - 1)
          })
        }}
      />

      {associatedThoughtsFiltered.length > 0 && editorEmpty ? (
        associatedThoughtsFiltered.map((thought, index) => {
          const authors = authorsByThoughtId[thought.id] || []
          const isSelected = index === selectedIndex
          const isReply = focusedThought
            ? focusedThought?.id in (thought?.connections?.outbound ?? {})
            : false
          if (!isSelected) return <></>
          return (
            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
              <div style={{ width: "100%" }}>
                <div
                  className="step-author-names-container"
                  style={{
                    color: "rgb(150,150,150)",
                    opacity: authors?.length > 0 ? "1" : ".25",
                    margin: "1em 1em -.5em",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {authors.length
                    ? "next step by " + concatenateNames(authors)
                    : "next step by Plexus"}
                </div>
                <WalkStep
                  {...{
                    step: undefined,
                    thought,
                    processThoughtClick: focusOnNewThought,
                    walkStepType: "small",
                    isSelected,
                    isFirstStep: false,
                    isLastStep: false,
                    isReply,
                  }}
                />
              </div>
            </div>
          )
        })
      ) : (
        <div style={{ width: "100%" }}>
          <WalkStep
            {...{
              step: undefined,
              thought: {
                text: " ",
                id: "fake",
                timestamp: Date.now(),
                authorId: "plexus=loader",
                authorName: "",
                authorEmail: "davey@plex",
              },
              processThoughtClick: () => undefined,
              walkStepType: "small",
              isFirstStep: false,
              isLastStep: false,
            }}
          ></WalkStep>
        </div>
      )}
      <CaretRight
        size={32}
        color={"gray"}
        className={
          "caret " + (selectedIndex >= associatedThoughtsFiltered.length ? "disabled" : "")
        }
        onClick={() => {
          setSelectedIndex((x) => {
            return Math.min(associatedThoughtsFiltered.length - 1, x + 1)
          })
        }}
      />
    </div>
  )
}

export default SingleNextStep
