import { useContext, useEffect, useState } from "react"
import PostContext, { TextPost } from "../../../ReactContexts/PostContext"
import { AuthorInfo } from "../../../Types/types"
import { getAllDailyPrompts, getPromptToday } from "../../AdminStuff/AdminPanel/AdminSendEmails"
import { backendWriter } from "../../App"
import { getUniqueAuthors } from "../UnifiedWalk/IncomingSteps/IncomingSteps"
import OutgoingStepthroughPreview from "../UnifiedWalk/IncomingSteps/OutgoingStepthroughPreview"
import { ThoughtWalkStep, ThoughtWalkStepWithSrcThoughtToo } from "../WalkInterfaces"
import "./BellPage.css"

export type FocusesOnNewThought = (
  thought: TextPost,
  firstStepId: string,
  focusedThought?: TextPost,
  isFirstStepAfterBreak?: boolean
) => Promise<{
  promise: Promise<any>
  newStep: ThoughtWalkStep
  thoughtPromise: Promise<any>
}>

const BellPage = () => {
  const { incomingReplies } = useContext(PostContext)
  const [authorsInfoById, setAuthorsInfoById] = useState<{
    [key: string]: AuthorInfo
  }>({})
  //Adds a new thought to person's unified walk

  const focusOnNewThought = (
    thought: TextPost,
    firstStepId: string,
    focusedThought?: TextPost,
    isFirstStepAfterBreak: boolean = true
  ) => {
    return backendWriter.stepToExistingThought(
      thought,
      focusedThought,
      isFirstStepAfterBreak,
      firstStepId
    )
  }
  const [dailyPrompts, setDailyPrompts] = useState([])
  const dailyPrompt = getPromptToday(dailyPrompts)
  //daily step
  useEffect(() => {
    getAllDailyPrompts()
      .then((prompts) => {
        const sortedPrompts = prompts.sort((a, b) => b.date - a.date)
        setDailyPrompts(sortedPrompts)
      })
      .catch((error) => {
        console.error("Error fetching daily prompts:", error)
      })
  }, [])

  //record peak

  useEffect(() => {
    backendWriter.recordNotifsPeak(Date.now())
  }, [])
  //Get the authors of the outgoing steps
  useEffect(() => {
    const fetchAuthors = async () => {
      const incomingStepperIds = incomingReplies.map((step) => step.stepperId).filter((e) => e)
      const uniqueAuthors = await getUniqueAuthors(incomingStepperIds)
      let tempAuthorsMap: {
        [key: string]: AuthorInfo
      } = uniqueAuthors
        .filter((e) => Object.values(e).length > 0)
        .reduce(
          (
            mapSoFar: {
              [authorId: string]: AuthorInfo
            },
            authorInfoObj: { [authorId: string]: AuthorInfo }
          ) => {
            return { ...mapSoFar, ...authorInfoObj }
          },
          {}
        )
      setAuthorsInfoById(tempAuthorsMap)
    }

    fetchAuthors()
  }, [incomingReplies])

  return (
    <div className="bell-page-container">
      {/* default message */}
      {!incomingReplies || incomingReplies.length == 0 ? (
        <div className="centered-gray-text">
          This is where you'll see Walkthroughs - instances where other community members Walk
          through your thoughts to forward their own thinking.
        </div>
      ) : (
        <></>
      )}

      {dailyPrompt ? (
        <div style={{ width: "100%", maxWidth: "500pt" }}>
          <div
            style={{
              color: "rgb(100,100,100)",
              margin: "1em 1em 0em",
              textAlign: "center",
              verticalAlign: "middle",
              fontStyle: "italic",
            }}
          >
            <span style={{ fontSize: "1.1em" }}>{dailyPrompt.prompt}</span>
            <br></br>
            <br></br>
          </div>
        </div>
      ) : (
        <></>
      )}

      {incomingReplies.map((step: ThoughtWalkStepWithSrcThoughtToo) => (
        <OutgoingStepthroughPreview
          {...{ step, isSelected: false, authorsInfoById, focusOnNewThought, index: -2 }}
        />
      ))}
    </div>
  )
}

export default BellPage
