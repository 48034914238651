import { useEffect, useState } from "react"
import "./App.css"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import LandingPage from "./LandingPage/LandingPage"
import AuthContext from "../ReactContexts/AuthContext"
// Initialize Firebase within the app
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"
import firebaseConfig from "../Firebase/FirebaseConfig"

import { getAuth, GoogleAuthProvider, onAuthStateChanged, User } from "firebase/auth"
import PrivateRoute from "./PrivateRoute/PrivateRoute"
import Demos from "./LandingPage/Demos"

import HackyAdmin from "./AdminStuff/OldHackyAdminPanel/HackyAdmin"
import HackyAdminLastWeek from "./AdminStuff/OldHackyAdminPanel/HackyAdminLastWeek"
import AdminPanel from "./AdminStuff/AdminPanel/AdminPanel"
import Orientation from "./Orientation/Orientation"
import Authenticating from "./PrivateRoute/Authenticating"
import InvitePage from "./InvitePage/InvitePage"
import FirebaseWriter from "../Firebase/FirebaseWriter"
import ConnectionAdminStats from "./AdminStuff/ConnectionAdminStats"
import IsolatedWalk from "./IsolatedWalk/IsolatedWalk"
import AdminDailyPrompts from "./AdminStuff/AdminPanel/AdminSendEmails"
import Write from "../Write/Write"
import { getDatabase } from "firebase/database"
import { DocumentProvider } from "../Write/contexts/DocumentContext"

//Firebase global variables
export const app = initializeApp(firebaseConfig)
export const database = getDatabase(app)
export const analytics = getAnalytics(app)
export const auth = getAuth()
export const provider = new GoogleAuthProvider()

export interface SingleThoughtBreadcrumb {
  id: string
}
export type ThoughtBreadcrumbsI = SingleThoughtBreadcrumb[]
export let ThoughtBreadcrumbs: ThoughtBreadcrumbsI = []

//for email verification link setup
export const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for this
  // URL must be in the authorized domains list in the Firebase Console.
  url: "https://plexus.earth/",
  // This must be true.
  handleCodeInApp: true,
}
//initialize it but don't define it yet
export let backendWriter: FirebaseWriter = new FirebaseWriter(
  undefined,
  undefined,
  undefined,
  undefined,
  undefined
)

//a global variable storing data about the logged-in person (tend to avoid "user" language. only two industries where customers are called "users" are high tech and hard drugs.)
// export let person: User

// top level plexus react component
function App() {
  const [person, setPerson] = useState<User>()
  const [personName, setPersonName] = useState<string>()

  //authentication callback

  //three steps:
  //get the user from the auth callback
  //use the user object to get the name info
  //once we have the name info

  //auth > setPerson
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log({ user })
        setPerson(user)
      }
    })
  }, [])

  return (
    <AuthContext.Provider value={{ person, setPerson, personName, setPersonName }}>
      <BrowserRouter>
        <Routes>
          {/* /p is the path for any bounded domain, including the main domain */}
          <Route path="/p/:placeId/authenticating" element={<Authenticating person={person} />} />
          <Route
            path="oldChats"
            element={<PrivateRoute {...{ person, setPerson, personName }} />}
          />
          <Route
            path="write/:documentId?"
            element={
              <DocumentProvider>
                <Write />
              </DocumentProvider>
            }
          />

          <Route path="/p" element={<PrivateRoute {...{ person, setPerson, personName }} />}>
            <Route
              path=":placeId"
              element={<PrivateRoute {...{ person, setPerson, personName }} />}
            >
              <Route
                path="orientation"
                element={<PrivateRoute {...{ person, setPerson, personName, orientation: true }} />}
              />
              <Route
                path="oldChats"
                element={<PrivateRoute {...{ person, setPerson, personName }} />}
              />
              <Route
                path="chat/:conversationIdLink"
                element={<PrivateRoute {...{ person, setPerson }} />}
              />
              <Route
                path=":community"
                element={<PrivateRoute {...{ person, setPerson, personName }} />}
              >
                <Route path="walk/:thoughtId" element={<IsolatedWalk />} />
              </Route>
              <Route path="walk/:thoughtId" element={<IsolatedWalk />} />
            </Route>
          </Route>

          <Route path="walk/:thoughtId" element={<IsolatedWalk />} />

          {/* <Route */}
          <Route path="/" element={<LandingPage />} />
          {/* a path that locks on the landing page, even if the person is already logged in 
          helpful if you're logged in but, as a dev, are trying to make / observe landing page changes */}

          <Route path="landing" element={<LandingPage />} />
          <Route path="invite12" element={<LandingPage />} />
          <Route path="micah-invite" element={<InvitePage code={"micah-invite"} />}></Route>
          <Route path="invite/micah" element={<InvitePage code={"micah"} />} />

          {/* <Route path="/import" element={<LiveImports />}></Route> */}
          <Route path="admin" element={<HackyAdmin />}>
            <Route path=":placeId" element={<HackyAdmin />} />
          </Route>
          <Route path="connectionAdminStats" element={<ConnectionAdminStats />} />

          <Route path="adminLastWeek" element={<HackyAdminLastWeek />}>
            <Route path=":placeId" element={<HackyAdminLastWeek />} />
          </Route>

          {/* all the same stuff */}
          <Route path="adminPanel" element={<AdminPanel />}>
            <Route path=":placeId" element={<AdminPanel />} />
          </Route>
          <Route path="dash" element={<AdminPanel />}>
            <Route path=":placeId" element={<AdminPanel />} />
          </Route>
          <Route path="dashboard" element={<AdminPanel />}>
            <Route path=":placeId" element={<AdminPanel />} />
          </Route>
          {/* a page with a list of loom Plexus demos (including one for r/auntienetwork :)  */}
          <Route path="demos" element={<Demos />} />
          <Route path="tc" element={<Navigate to="/p/tc" />} />
          <Route path="orientation" element={<Orientation />}></Route>
          <Route path="prompts" element={<AdminDailyPrompts />}></Route>
        </Routes>
      </BrowserRouter>
    </AuthContext.Provider>
  )
}

export default App
