import { useContext } from "react"
import DisplayContext from "../../../ReactContexts/DisplayContext"
import PostContext, { TextPost } from "../../../ReactContexts/PostContext"
import { backendWriter } from "../../App"
import { usernamex } from "../../PrivateRoute/PrivateRoute"
import { ThoughtWalkStepWithThought } from "../WalkInterfaces"
import { WalkStep } from "../WalkStep"
import HideStepsToggle from "./HideStepsToggle"
import "./PastSteps.css"

const PastSteps = ({
  orderedPastStepThoughts,
  orderedPastSteps,
  // selectedIndex,
  stepToExistingThought,
  expandedStepIds,
  setExpandedStepIds,
  editorEmpty,
  selectedPastStepIndex,
  disableToggle,
}: {
  stepToExistingThought: (thought: TextPost, isFirstStepAfterBreak: boolean) => any
  orderedPastStepThoughts: TextPost[]
  orderedPastSteps: ThoughtWalkStepWithThought[]
  selectedIndex?: number
  selectedPastStepIndex?: number
  expandedStepIds: { [stepId: string]: true }
  setExpandedStepIds: React.Dispatch<React.SetStateAction<{ [stepId: string]: true }>>
  editorEmpty: boolean
  disableToggle?: boolean
}) => {
  const { loadingPosts } = useContext(PostContext)
  const { enableStrikes, setShowPastSteps, showPastSteps } = useContext(DisplayContext)
  const endpoints = orderedPastSteps
    .map((e, i) => {
      return { ...e, orderIndex: i }
    })
    .filter((e) => e.wasLastStepInWalk || e.strike)
  if (disableToggle) setShowPastSteps(true)
  const lastEndpoint = endpoints.length > 0 ? endpoints[endpoints.length - 1] : undefined
  const lastEndpointIndex = lastEndpoint ? lastEndpoint.orderIndex : undefined

  const stepsBeforeThisWalk = lastEndpointIndex ? orderedPastSteps.slice(0, lastEndpointIndex) : []

  const realSelectedIndex = orderedPastStepThoughts.length - (selectedPastStepIndex ?? 1)
  return (
    <div className="past-steps-container" style={{ opacity: editorEmpty ? "1" : ".2" }}>
      {showPastSteps || orderedPastStepThoughts.length === 0 || disableToggle ? (
        <></>
      ) : (
        <HideStepsToggle {...{ setShowPastSteps, showPastSteps, stepsBeforeThisWalk }} />
      )}
      {orderedPastStepThoughts.length === 0 && !loadingPosts ? (
        <div style={{ textAlign: "center", fontSize: "1.1em", color: "gray" }}>
          Welcome, {usernamex} :)
          <br></br> <br></br>
          Plexus is a place to think with other people's thoughts. <br></br>
          <br></br>
          To start, write about something that's been on your mind. Then press enter.
        </div>
      ) : (
        <></>
      )}

      {orderedPastStepThoughts.map((thought, i) => {
        const isSelected = realSelectedIndex === i

        //const isLastStep

        //determine if last step based on whether it has the last step attribute
        //determine if is first step
        const step = orderedPastSteps[i]
        const isFirstStep = step?.wasFirstStepInWalk
        const isLastStep = step?.wasLastStepInWalk

        //endpoints are walkends (last steps) and strikes. if it's between two endpoints, close it.
        const isStrike = step?.strike?.timestamp > 0
        const isEndpoint = isStrike || isLastStep

        const isEndpointExpanded = step?.walkStepId in expandedStepIds
        const nextEndpoints = orderedPastSteps
          .slice(i)
          .filter((e) => e.wasLastStepInWalk || e.strike)

        const nextEnds = orderedPastSteps.slice(i).filter((e) => e.wasLastStepInWalk)
        const nextEndpoint = nextEndpoints.length > 0 ? nextEndpoints[0] : undefined
        const nextEnd = nextEnds.length > 0 ? nextEnds[0] : undefined
        const lastEnd = nextEnds.length > 0 ? nextEnds[nextEnds.length - 1] : undefined
        const isLastEnd = step?.walkStepId === lastEnd?.walkStepId

        const nextEndpointExpanded =
          nextEndpoint && nextEndpoint.walkStepId in (expandedStepIds ?? {}) ? true : undefined

        //either it's a plain step without a next endpoint, or it's a strike
        const shouldAppear =
          !enableStrikes || !nextEndpoint || nextEndpointExpanded || isStrike || isSelected

        const previousStepThought = i > 0 ? orderedPastStepThoughts[i - 1] : undefined
        const isReply = previousStepThought
          ? previousStepThought?.id in (thought?.connections?.outbound ?? {})
          : false
        if (!showPastSteps && nextEnd) return <></>
        // !nextEndpoint || isStrike
        return (
          <div className="past-walk-step" key={thought?.id + i + "-step"}>
            {isEndpoint ? (
              isEndpointExpanded ? (
                <div
                  className="collapsed-step"
                  style={{
                    textAlign: "center",
                    // margin: "-.96ex 0",
                    cursor: "pointer",
                    fontStyle: "italic",
                  }}
                  onClick={() => {
                    //toggle appearance of all these thoughts
                    setExpandedStepIds((prev) => {
                      let newCopy = { ...prev }
                      delete newCopy[step.walkStepId]
                      return newCopy
                    })
                  }}
                  title={`Collapse these thoughts`}
                >
                  collapse
                </div>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
            {shouldAppear ? (
              <>
                <WalkStep
                  {...{
                    step,
                    key: "breadcrumb-thought-" + thought?.id,
                    thought,
                    i,
                    processThoughtClick: (thought: TextPost) =>
                      stepToExistingThought(thought, isFirstStep),
                    walkStepType: "",
                    isCopy: "",
                    lastItem:
                      i >= orderedPastStepThoughts?.length - 1 && !isLastStep
                        ? "lastItem"
                        : "notLast",
                    isSelected,
                    isFirstStep,
                    isLastStep,
                    shouldntAppear: !shouldAppear,
                    isPastStep: "past-step",
                    isReply,
                  }}
                />
                <></>
              </>
            ) : true ? (
              <div
                className="collapsed-step"
                style={{
                  textAlign: "center",
                  margin: "-1.7ex 0 0",
                  cursor: "pointer",
                  fontSize: "1em",
                }}
                onClick={() => {
                  //toggle appearance of all these thoughts
                  setExpandedStepIds((prev) => {
                    if (!nextEndpoint) return prev
                    else return { ...prev, [nextEndpoint.walkStepId]: true }
                  })
                }}
                title={`Expand these thoughts`}
              >
                ________________
              </div>
            ) : (
              <></>
            )}

            {/* {isEndpoint ? (
              <div className="centered-ellipses">
                {!isEndpointExpanded ? (
                  <></>
                ) : (
                  <DotsThreeVertical
                    size={25}
                    onClick={() => {
                      setExpandedStepIds((x) => {
                        const newX = { ...x }
                        if (step?.walkStepId) delete newX[step?.walkStepId]
                        return newX
                      })
                    }}
                  />
                )}
              </div>
            ) : (
              <></>
            )} */}
            {/* whether to show ellipses thing */}

            {/* whether to show a break */}
            {/* experiment */}
            {isLastStep ? (
              <div
                className="centered-walk-break"
                onClick={() => {
                  //undo the last step designation
                  if (window.confirm("Remove break?"))
                    backendWriter.removeLastStepStatus(step?.walkStepId)
                }}
              ></div>
            ) : (
              <></>
            )}
            {isLastEnd && orderedPastStepThoughts.length > 0 ? (
              <HideStepsToggle {...{ setShowPastSteps, showPastSteps, stepsBeforeThisWalk }} />
            ) : (
              <></>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default PastSteps
