// @flow strict
import * as React from "react"
import { useParams } from "react-router-dom"
import { BaseEditor, createEditor, Descendant, Editor, Node, Transforms } from "slate"
import { HistoryEditor, withHistory } from "slate-history"
import { Slate, withReact, Editable, ReactEditor } from "slate-react"
import { TextPost } from "../../../ReactContexts/PostContext"
import { backendWriter } from "../../App"
import { initialValue } from "../../Editor/EditorContainer"
import "./ReplyInputField.css"

function ReplyInputField({
  parentThought,
  providedEmpty: empty,
  providedSetEmpty: setEmpty,
  callback,
}: {
  parentThought: TextPost
  providedEmpty: boolean
  providedSetEmpty: React.Dispatch<React.SetStateAction<boolean>>
  callback: Function
  firstInWalk?: true
}) {
  const [value, _setValue] = React.useState(initialValue)
  const [editor, _setEditor] = React.useState(() => withHistory(withReact(createEditor())))
  const { placeId } = useParams()
  const resetEditor = () => {
    Transforms.delete(editor, {
      at: {
        anchor: Editor.start(editor, []),
        focus: Editor.end(editor, []),
      },
    })
  }
  const onChange = (children: Descendant[]) => {
    if (children.length > 0 && Node.string(children[0]).length > 0) {
      setEmpty(false)
    } else setEmpty(true)
  }
  React.useEffect(() => {
    ReactEditor.focus(editor)
    Transforms.select(editor, Editor.end(editor, []))
  }, [])
  const replyBackend = (e: any) => {
    //if editor isn't empty, submit a reply
    //thought id should always be defined
    if (!empty && !e.shiftKey) {
      const string = Node.string(editor)
      callback()
      backendWriter.addReplyThought(string, parentThought, placeId, editor.children)

      e.preventDefault()
      resetEditor()
      setEmpty(true)
    }
  }
  const handleKeyDown: React.KeyboardEventHandler = (e) => {
    switch (e.key.toLocaleLowerCase()) {
      case "enter":
        replyBackend(e)
        break
    }
  }

  return (
    <div className="feed-item-container">
      <Slate {...{ editor, value, onChange }}>
        {/* <div className="reply-row"> */}
        <Editable
          style={{ width: "100%" }}
          placeholder={"Express anything..."}
          onKeyDown={handleKeyDown}
          autoFocus={true}
        />
      </Slate>

      {empty ? (
        <></>
      ) : (
        <button className={"reply-submit-button" + (empty ? " empty" : "")} onClick={replyBackend}>
          {"↑"}
        </button>
      )}
    </div>
  )
}

export function SimpleReplyInput({
  value,
  editor,
  onSubmit,
  setEmpty,
}: {
  value: Descendant[]
  editor: BaseEditor & ReactEditor & HistoryEditor
  onSubmit: (_: any, children?: Descendant[]) => Promise<void>
  setEmpty: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const [isEmpty, setIsEmpty] = React.useState(true)

  const onChange = (children: Descendant[]) => {
    if (children.length > 0 && Node.string(children[0]).length > 0) {
      setEmpty(false)
      setIsEmpty(false)
    } else {
      setEmpty(true)
      setIsEmpty(true)
    }
  }

  const focusEditor = () => {
    ReactEditor.focus(editor)
    Transforms.select(editor, Editor.end(editor, []))
  }

  React.useEffect(() => {
    focusEditor()
  }, [])
  const replyBackend = () => {
    //if editor isn't empty, submit a reply
    //thought id should always be defined
    const string = Node.string(editor)
    onSubmit({ text: string }, editor?.children)
  }
  const handleKeyDown: React.KeyboardEventHandler = (e) => {
    switch (e.key.toLocaleLowerCase()) {
      case "enter":
        e.preventDefault()
        if (e.metaKey) {
          backendWriter.addWalkBreak()
          onSubmit({ text: Node.string(editor) ?? "" }, editor?.children)
          break
        } else {
          replyBackend()
          break
        }
    }
  }

  const myRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (myRef.current) {
      myRef.current.focus()
    }
  }, [])

  return (
    <div className={`input-field thought-walk-step small ${isEmpty ? "empty" : ""}`}>
      <Slate {...{ editor, value, onChange }}>
        <Editable
          style={{ width: "100%" }}
          onKeyDown={handleKeyDown}
          className={`slate-placeholder ${isEmpty ? "is-empty" : ""}`}
        />
      </Slate>
    </div>
  )
}

export default ReplyInputField
