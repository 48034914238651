import React, { createContext, useContext, useState } from "react"

interface DocumentContextType {
  documentId: string | null
  setDocumentId: React.Dispatch<React.SetStateAction<string | null>>
}

type DocumentProviderProps = {
  children: React.ReactNode
}

const DocumentContext = createContext<DocumentContextType | undefined>(undefined)

export const useDocumentContext = () => {
  const context = useContext(DocumentContext)
  if (!context) {
    throw new Error("useDocumentContext must be used within a DocumentProvider")
  }
  return context
}

export const DocumentProvider: React.FC<DocumentProviderProps> = ({ children }) => {
  const [documentId, setDocumentId] = useState<string | null>(null)

  return (
    <DocumentContext.Provider value={{ documentId, setDocumentId }}>
      {children}
    </DocumentContext.Provider>
  )
}
