import { Alerts, windowUpdate } from "../../Types/types"
import { backendWriter } from "../App"

/*
Example updateAndSurvey object
    {
      window: "prompt",
      title: "enjoyingPlexus",
      text: "Have you been enjoying Plexus?",
      timestamp: 1680902285550,
    }
*/

export const Survey = (alerts: Alerts, forceSurvey?: boolean) => {
  console.log(alerts)
  // Use Date.now() in a node terminal to get current timestamp

  const updatesAndSurvey: windowUpdate[] = [
    // Commenting out now that we're going to send the survey individually to everyone.
    // {
    //   window: "prompt",
    //   title: "walkDisappointment",
    //   text: "We want to get a sense of your experience! \n\nHow disappointed would you be if you could no longer be part of Plexus?\n\n1 - Not so disappointed \n2 - Somewhat disappointed \n3 - Very disappointed",
    //   timestamp: 1689566401,
    //   // //saturday july 15
    // },
    // {
    //   window: "prompt",
    //   title: "walkDisappointmentOpen",
    //   text: "We're curious why!",
    //   timestamp: 1689566401,
    //   // //saturday july 15
    // },
    // {
    //   window: "alert",
    //   title: "walkDisappointmentOpen",
    //   text: "Micah and I appreciate it. \n\nMessage me with anything.\n(646) 352-2244 / davey@plexus.earth\n\n - Davey (founder)",
    //   timestamp: 1689379201000,
    //   // //saturday july 15
    // },
  ]

  const sendWindowMessage = (alertItem: windowUpdate) => {
    alertItem.timestamp = Date.now()
    if (alertItem.window === "prompt") {
      let response = alertItem
      response.response = window.prompt(alertItem.text)
      backendWriter.recordWindowResponse(response)
    }
    if (alertItem.window === "alert") {
      window.alert(alertItem.text)
      backendWriter.recordWindowResponse(alertItem)
    }
  }
  for (let i = 0; i < updatesAndSurvey.length; i++) {
    console.log(alerts)
    if (!alerts.alerts) {
      sendWindowMessage(updatesAndSurvey[i])
      // If alerts does exist but the name of the alert does not exist, send the alert
    } else if (
      alerts !== null &&
      (!(updatesAndSurvey[i].title in alerts?.alerts) ||
        (forceSurvey && updatesAndSurvey[i].window !== "alert"))
    ) {
      console.log("second condition, alerts: ", alerts)
      sendWindowMessage(updatesAndSurvey[i])
    }
    // Preventing alert spam
    setTimeout(() => {}, 5000)
  }
}
