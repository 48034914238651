import { Gift } from "@phosphor-icons/react"
import { Dispatch, SetStateAction } from "react"
import { Editor } from "slate"
import { ReactEditor } from "slate-react"
import { backendWriter } from "../../../App"
import "./CollapseWalkButton.css"

const CollapseWalkButton = ({
  lastStepId,
  editor,
  setExpandedStepIds,
}: {
  lastStepId: string
  editor: Editor
  setExpandedStepIds: Dispatch<SetStateAction<{}>>
}) => {
  return (
    <div
      className="collapse-walk-button-container"
      title="wrap current walk, like a gift"
      onClick={() => {
        //add last step attribute to the given thought
        window.alert("Woohoo! Very very nice Walk.")
        backendWriter.makeIntoLastStep(lastStepId)
        setExpandedStepIds({})
        // setSeeBeforeCurrentWalk(false)
        setTimeout(() => ReactEditor.focus(editor), 100)
      }}
    >
      {/* Close &nbsp; */}
      {/* <ArrowLineUp size={32} /> */}
      {/* + */}
      wrap &nbsp;
      <Gift size={20} className="collapse-icon" />
      &nbsp; walk
    </div>
  )
}

export default CollapseWalkButton
