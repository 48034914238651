import { useContext } from "react"
import AuthContext from "../../ReactContexts/AuthContext"
import DisplayContext from "../../ReactContexts/DisplayContext"
import PostContext from "../../ReactContexts/PostContext"
import { auth, backendWriter } from "../App"
import "./Settings.css"

const Settings = () => {
  const { person } = useContext(AuthContext)
  const { personBucket } = useContext(PostContext)
  const { enableStrikes, setEnableStrikes, otherDisplaySettings, setOtherDisplaySettings } =
    useContext(DisplayContext)

  return (
    <div className="settings-container">
      <div className="app-section-header">Settings</div>
      <button
        onClick={() => {
          if (window.confirm("log out of " + person.email + "?")) {
            auth
              .signOut()
              .then(() => console.log(person.uid, person.email, "signed out"))
              .catch(() => console.warn(person.uid, person.email, "error signing out"))
            window.location.reload()
          }
        }}
      >
        Log out ({person.email})
      </button>
      <button
        onClick={() => {
          const newNameX = window.prompt("What would you like your new display name to be?")
          if (newNameX) {
            backendWriter
              .setName(newNameX)
              .then(() => {
                window.alert(
                  "On all your future steps, " +
                    newNameX +
                    " will be your new display name. Past steps will still have the same name."
                )
              })
              .catch((e) => window.alert("An error! Send this to Davey... " + e))
          }
        }}
      >
        Change display name (Currently "{personBucket?.personName}")
      </button>
      <button
        onClick={() => {
          window.location.href = "mailto:davey@plexus.earth?subject=Plex Feedback&body=Hey Davey,"
        }}
      >
        Send us feedback
      </button>
      <button
        onClick={() => {
          setEnableStrikes((prev) => !prev)
        }}
      >
        {enableStrikes ? "disable" : "enable"} walk-collapses ("strikes")
      </button>
      <button
        onClick={() => {
          setOtherDisplaySettings((x) => {
            return { ...x, indentLikeConversation: !x.indentLikeConversation }
          })
        }}
        title="indent steps in walk to look like a convo"
      >
        {otherDisplaySettings?.indentLikeConversation
          ? "disable conversation view"
          : "enable conversation view"}
      </button>
      <br></br> Links<br></br>
      <button
        onClick={() => {
          window.location.href = "https://plexus.substack.com"
        }}
      >
        Plexus Blog
      </button>
      <button
        onClick={() => {
          window.location.href =
            "https://innovative-phosphorus-3ab.notion.site/Joining-Plexus-370dd63ea16d463e8b34ec629625d344"
        }}
      >
        Joining Plexus
      </button>
      <a href="https://docs.google.com/document/d/1BoqTmmcaNUlaur7tG4o7Pdm8rRCoHLNZ/edit?usp=sharing&ouid=112785312491031312241&rtpof=true&sd=true">
        Privacy Policy
      </a>
      <a href="https://docs.google.com/document/d/1B8n2TaviaGY7knglzkk1OSezraFzpS9p/edit?usp=sharing&ouid=112785312491031312241&rtpof=true&sd=true">
        Terms of Service
      </a>
      {/* <div className="column"></div> */}
    </div>
  )
}

export default Settings
