import React from "react"

interface CitationsListProps {
  cites: Array<{ id: string; text: string; timestamp: string; authorName: string }>
}

const CitationsList: React.FC<CitationsListProps> = ({ cites }) => {
  return (
    <div className="citations-list">
      {cites.length > 0 ? <h3> References </h3> : <></>}
      {cites.map((cite, index) => (
        <div key={cite.id} title={cite.text} className="cite">
          {index + 1}
          {". "}
          {cite.authorName}
          {`. (`}
          {new Date(cite.timestamp).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "long",
            year: "numeric",
          })}
          {"). " + cite.text}
        </div>
      ))}
    </div>
  )
}

export default CitationsList
