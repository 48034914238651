import { EdgeInfo, TextPost } from "../ReactContexts/PostContext"

/**
 * Returns an array of all the unique thoughts only
 * Wrote draft of this, haven't tested yet
 * @param thoughts
 */
export const getUniqueThoughts = (thoughts: TextPost[]): TextPost[] => {
  const uniques = thoughts.reduce((uniqueThoughtsSoFar: TextPost[], nextThought: TextPost) => {
    const uniqueThoughtsSoFarIds = uniqueThoughtsSoFar.map((thought) => thought.id)
    if (uniqueThoughtsSoFarIds.includes(nextThought.id)) {
      return uniqueThoughtsSoFar
    } else return [...uniqueThoughtsSoFar, nextThought]
  }, [])
  return uniques
}

/**
 * Function to get the Id of the edge's actual author
 *  Before this, we were assuming that the edge's author is the author of the source thought, which is no longer always true now that rabbit holing / vicarious connection-making is possible.
 * TODO write this function with new logic
 * @param e
 */
export function getEdgeAuthor(e: EdgeInfo): string {
  return "edgeAuthorId" in e ? e.edgeAuthorId : e.authorId
}
