import { getDatabase, onValue, ref } from "firebase/database"
import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import AuthContext from "../../../ReactContexts/AuthContext"
import PostContext from "../../../ReactContexts/PostContext"
import { backendWriter } from "../../App"
import "./SurveyPopup.css"

const SurveyPopup = () => {
  //not loaded
  const [surveyLoaded, setSurveyLoaded] = useState(false)
  const [surveySeen, setSurveySeen] = useState<boolean>()
  const { placeId } = useParams()
  const { person } = useContext(AuthContext)
  const { mySteps, personBucket } = useContext(PostContext)
  //Like one day ago, makes sure this is a new session
  const lastUpdatesPeakWasYesterday =
    personBucket?.timestamps?.lastPeakedAllUpdates &&
    Date.now() - personBucket.timestamps.lastPeakedAllUpdates > 86400

  useEffect(() => {
    const domainId = placeId ?? "forum"
    const db = getDatabase()
    const orientationRef = ref(
      db,
      "p/" + domainId + `/personToMonthlySurveys/${person?.uid}/august/`
    ) //nodes are posts

    onValue(orientationRef, (data) => {
      //get whether the person is oriented
      if (data.exists) {
        setSurveyLoaded(true)
        setSurveySeen(data.val())
      }
    })
  })
  return surveyLoaded &&
    !surveySeen &&
    Object.keys(mySteps ?? {}).length > 10 &&
    lastUpdatesPeakWasYesterday ? (
    <div className="survey-popup-container">
      <div className="survey-window">
        {" "}
        <div className="survey-oneliner">
          We'd love your feedback in our monthly
          <div
            className="fake-link"
            onClick={() => {
              window.open("https://forms.gle/4hd43A8CF9GK52Hq7", "_blank")
              backendWriter.markSurveyClicked()
            }}
          >
            one-question survey!
          </div>
        </div>
        <div
          style={{
            maxWidth: "300pt",
            fontStyle: "italic",
            padding: "2em",
            // maxHeight: "50vh",
            // overflowY: "auto",
          }}
        >
          (Click the survey link to start Walking. Feel free not to fill it out.)
          <br></br> <br></br>
          We're asking for feedback from Walkers who have taken more 10 steps. Here's why:
          <br></br> <br></br>
          Most social media companies measure engagement (eg daily active users, miles scrolled,
          etc) as their North Star. They assume high usage = high positive impact (or neglect
          impact). Scrolling for hours, which may feel shitty, still scores high; in fact, it's a
          jackpot.
          <br></br>
          <br></br>
          Plexus is different. As a public benefit company, the thing we measure will always be the
          impact Plexus makes on your life, as reported by you. Thus: a one-question monthly survey.
          <br></br>
          <br></br>
          We're so so grateful for you. You're making possible the first text-based social network
          where everyone is contributing. You're fucking awesome.
          <br></br>
          <br></br>Warmly, and w/ mischief,
          <br></br>
          <br></br>
          Davey & Micah
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default SurveyPopup
