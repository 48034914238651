import moment from "moment"
import { useContext, useEffect } from "react"
import { thoughtIdsTraversedThisSession } from "../../../../Firebase/FirebaseWriter"
import AuthContext from "../../../../ReactContexts/AuthContext"
import DisplayContext from "../../../../ReactContexts/DisplayContext"
import { backendWriter } from "../../../App"
import { FocusesOnNewThought } from "../../BellPage/BellPage"
import { ThoughtWalkStepWithSrcThoughtToo } from "../../WalkInterfaces"
import { WalkStep } from "../../WalkStep"
import { focusedStepId } from "../UnifiedWalk"
import "./OutgoingStepthroughPreview.css"
import { AuthorInfo } from "../../../../Types/types"

const OutgoingStepthroughPreview = ({
  step,
  focusOnNewThought,
  isSelected,
  authorsInfoById,
  index,
}: {
  step: ThoughtWalkStepWithSrcThoughtToo
  focusOnNewThought: FocusesOnNewThought
  isSelected: boolean
  authorsInfoById: { [key: string]: AuthorInfo }
  index: number
}) => {
  const { setAppView } = useContext(DisplayContext)
  const { person } = useContext(AuthContext)

  //get more recent lastTraversed info
  const thought = step?.thought
  const seenReply =
    (thought.lastTraversed ? thought.lastTraversed[person.uid] > 0 : false) ||
    thought?.id in thoughtIdsTraversedThisSession

  useEffect(() => {}, [])

  if (!thought) return <></>
  const author = authorsInfoById[step?.stepperId]

  const stringTime = moment(step?.timestamp).fromNow()

  //what to do when person clicks on a stepthru preview
  const handleStepthroughClick = () => {
    if (!step.thought || !step.sourceThought) return

    const { sourceThought, thought } = step

    //add break to last thought, if doesn't already exist TODO
    if (focusedStepId) backendWriter.makeIntoLastStep(focusedStepId)

    //step on first thought
    focusOnNewThought(sourceThought, undefined, undefined, true).then(() => {
      //step on second thought (with edge)
      //don't do this too.
      focusOnNewThought(thought, undefined, sourceThought, false)
    })

    setAppView("walk")
  }
  return (
    <div
      key={`${thought?.id}-incoming-${index}-${step?.walkStepId}`}
      className="outgoing-stepthrough-preview-container"
      onClick={handleStepthroughClick}
    >
      {/* <div>{seenReply ? "have seen" : "not seen"}</div> */}
      <div
        style={{
          color: "rgb(100,100,100)",
          margin: "1em 1em 0em",
          textAlign: "center",
          verticalAlign: "middle",
          fontWeight: !seenReply ? "bold" : "normal",
        }}
      >
        {!seenReply ? <span> ● &nbsp;</span> : <></>}
        {author?.personName ?? "someone"} replied to your thought ({stringTime})
      </div>
      <WalkStep
        {...{
          step: undefined,
          thought,
          processThoughtClick: () => {}, //real click function is above
          walkStepType: "stepthrough-preview",
          isSelected,
          isFirstStep: false,
          isLastStep: false,
        }}
      />
    </div>
  )
}

export default OutgoingStepthroughPreview

const sourceThoughtSeenSince = (
  step: ThoughtWalkStepWithSrcThoughtToo,
  lastTimeSeen: number
): boolean => {
  return step.timestamp < lastTimeSeen
}
export const stepThroughSeen = sourceThoughtSeenSince
export const stepThroughVisited = (
  personId: string,
  step: ThoughtWalkStepWithSrcThoughtToo
): boolean => {
  const thought = step?.sourceThought
  const lastTraversedTime = thought?.lastTraversed ? thought?.lastTraversed[personId] : -1
  return (
    lastTraversedTime >= 0 ||
    (thought?.id in thoughtIdsTraversedThisSession &&
      thoughtIdsTraversedThisSession[thought.id] > lastTraversedTime)
  )
}
