import { ref, set, update, remove, push, get } from "firebase/database"
import { database } from "../../Components/App"
import { Cite, ThoughtWithSuggestions } from "../WriteTypes"

type DocumentCallback = (data: any) => void

export const createDocument = async (
  title: string,
  thoughts: any[],
  cites: any[],
  authorId: string,
  authorEmail: string,
  authorName: string
): Promise<string> => {
  const newDocRef = push(ref(database, "documents/"))
  await set(newDocRef, {
    title,
    thoughts,
    cites,
    authorId,
    authorEmail,
    authorName,
    timeCreated: Date.now(),
  })
  return newDocRef.key || "" // Return the generated unique ID
}

export const fetchDocument = (documentId: string, callback: DocumentCallback): any => {
  const document = get(ref(database, "documents/" + documentId)).then((snapshot) => {
    const data = snapshot.val()
    callback(data)
  })

  // Return the cleanup function directly
  return document
}

export const updateDocument = (
  documentId: string,
  title: string,
  thoughts: ThoughtWithSuggestions[],
  cites: Cite[]
) => {
  console.log("updated triggered!")
  return update(ref(database, "documents/" + documentId), {
    title,
    thoughts,
    cites,
  })
}

export const deleteDocument = (documentId: string) => {
  return remove(ref(database, "documents/" + documentId))
}
