import React, { Dispatch, SetStateAction } from "react"
import { AppViewOption } from "./PostContext"
export interface embeddingsMap {
  [thoughtId: string]: number[]
}

export type OtherDisplaySettings = {
  indentLikeConversation?: boolean
}
/**
 * Context for all high level info retrieved from firebase
 */
let DisplayContext = React.createContext<{
  appView: AppViewOption
  setAppView: Dispatch<SetStateAction<AppViewOption>>
  enableStrikes: boolean
  setEnableStrikes: Dispatch<SetStateAction<boolean>>
  showWalkthroughs: boolean
  setShowWalkthroughs: Dispatch<SetStateAction<boolean>>
  showPastSteps: boolean
  setShowPastSteps: Dispatch<SetStateAction<boolean>>
  setOtherDisplaySettings: Dispatch<SetStateAction<OtherDisplaySettings>>
  otherDisplaySettings: OtherDisplaySettings
}>({
  appView: "walk",
  setAppView: () => false,
  enableStrikes: true,
  setEnableStrikes: () => false,
  showWalkthroughs: false,
  setShowWalkthroughs: () => false,
  showPastSteps: false,
  setShowPastSteps: () => false,
  otherDisplaySettings: { indentLikeConversation: false },
  setOtherDisplaySettings: () => false,
})
export default DisplayContext
